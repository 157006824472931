import { useMutation, gql } from '@apollo/client'
import { MutationHookParams } from 'gql/sharedTypes/types'

export interface RegisterUserResponse {
  register: {
    success: boolean
    error?: string | null
    data: {
      user: {
        id: number
      }
      token: string
    } | null
  } | null
}

export interface RegisterUserInput {
  email: string
  password: string
  firstName: string
  lastName: string
  emailList: boolean
  inviteToken: string | null
  trial: boolean
}

const REGISTER_USER_MUTATION = gql`
  mutation RegisterMutation($input: RegisterUserInput!) {
    register(input: $input) {
      success
      error
      data {
        user {
          id
        }
        token
      }
    }
  }
`

export const useRegisterUserMutation = (options?: MutationHookParams) => {
  const [mutation, { data, loading, error }] = useMutation<RegisterUserResponse>(
    REGISTER_USER_MUTATION,
    options
  )

  const registerUser = (registerUser: RegisterUserInput) =>
    mutation({
      variables: {
        input: registerUser
      }
    })

  return {
    registerUser,
    data,
    loading,
    error
  }
}
