import { CardHeader, CardContent } from '../ui/Card'
import Skeleton from '../ui/Skeleton'

export const SleketonCard = () => {
  return (
    <Skeleton className="h-48 flex">
      <div className="w-[60%] flex flex-col gap-2">
        <CardHeader>
          <Skeleton className="w-full h-5" />
          <Skeleton className="w-2/3 h-4" />
        </CardHeader>
        <CardContent className="mt-auto">
          <Skeleton className="w-full h-4" />
        </CardContent>
      </div>
      <div className="w-[40%]">
        <CardContent className="pt-4 h-full">
          <Skeleton className="w-full h-full" />
        </CardContent>
      </div>
    </Skeleton>
  )
}

export default SleketonCard
