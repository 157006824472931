import { useMutation, gql } from '@apollo/client'
import { MutationHookParams } from 'gql/sharedTypes/types'

export interface EmailRegisterResponse {
  emailRegister: {
    success: boolean | null
    error: string | null
    data: {
      user: {
        id: number
      }
      token: string
    } | null
  } | null
}

export interface EmailRegisterCredentials {
  email: string
  credentials: string | null
  trial: boolean
}

const EMAIL_REGISTER_MUTATION = gql`
  mutation EmailRegisterMutation($input: EmailRegisterInput!) {
    emailRegister(input: $input) {
      success
      error
      data {
        token
        user {
          id
        }
      }
    }
  }
`

export const useEmailRegisterMutation = (options?: MutationHookParams) => {
  const [mutation, { data, loading, error }] = useMutation<EmailRegisterResponse>(
    EMAIL_REGISTER_MUTATION,
    options
  )

  const emailRegister = (credentials: EmailRegisterCredentials) =>
    mutation({
      variables: {
        input: credentials
      }
    })

  return {
    emailRegister,
    data,
    loading,
    error
  }
}
