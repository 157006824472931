import { useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { useUser } from '~/graphql/hooks/useUser'
import {
  useUpdateOrgMutation,
  UpdateOrgInput
} from '~/graphql/hooks/useUpdateOrgMutation'
import * as z from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { ArrowLeftIcon, LoaderCircleIcon } from 'lucide-react'
import { Link } from 'react-router-dom'
import Label from '~/components/ui/Label'
import Input from '~/components/ui/Input'
import Button from '~/components/ui/Button'
import { MemberList } from '~/components/Organization/MembersList'

const updateOrgSchema = z.object({
  name: z.string().trim().min(1, { message: 'Organization name is required' })
})

type UpdateOrgForm = z.infer<typeof updateOrgSchema>

function OrgSettings(): JSX.Element {
  const params = useParams<{ orgId: string }>()
  const orgId = parseInt(params.orgId!)
  const { user } = useUser()
  const org = user?.orgs.find((org) => org.id === orgId)
  const userCanEdit = ['owner', 'administrator'].includes(org?.role ?? '')

  const { updateOrg, loading: updateOrgLoading } = useUpdateOrgMutation({
    refetchQueries: ['NavigationDataQuery', 'MeQuery']
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty }
  } = useForm<UpdateOrgForm>({
    defaultValues: {
      name: org?.name ?? ''
    },
    resolver: zodResolver(updateOrgSchema)
  })

  const orgNameInput = register('name')

  const onUpdateOrgSumit = async (form: UpdateOrgForm) => {
    if (!userCanEdit) return

    const updateOrgPayload: UpdateOrgInput = {
      id: orgId,
      name: form.name,
      type: 'business'
    }

    const updateOrgResponse = await updateOrg(updateOrgPayload)

    if (updateOrgResponse) {
      reset({
        name: form.name
      })
      toast.success('Organization name updated')
    }
  }

  return (
    <div className="container mx-auto min-h-full flex flex-col">
      <section className="h-[72px] border-b border-b-divider flex items-center gap-4">
        <Link
          to={`/org/${orgId}/projects`}
          className="p-2 rounded-full border border-divider hover:bg-fundation-emphasis-hover transition-colors"
        >
          <ArrowLeftIcon size={16} />
        </Link>
        <h3 className="text-2xl font-semibold">Organization Settings</h3>
      </section>

      <section className="flex-grow w-[992px] mx-auto py-6 flex flex-col gap-6 justify-start">
        <h4 className="text-xl font-semibold">Settings</h4>

        <div>
          <form
            className="max-w-96 grid gap-1"
            onSubmit={(event) => void handleSubmit(onUpdateOrgSumit)(event)}
          >
            <Label className="text-sm text-content-secondary">Organization Name</Label>
            <div className="flex gap-2">
              <Input
                wrapperClassName="flex-grow"
                {...orgNameInput}
                onChange={(_, event) => void orgNameInput.onChange(event)}
                error={errors.name?.message}
                readOnly={!userCanEdit}
              />
              <Button type="submit" disabled={updateOrgLoading || !isDirty}>
                {updateOrgLoading && (
                  <LoaderCircleIcon className="mr-2 h-5 w-5 animate-spin" />
                )}
                Save
              </Button>
            </div>
          </form>
        </div>

        {userCanEdit && <MemberList orgId={orgId} />}
      </section>
    </div>
  )
}

export default OrgSettings
