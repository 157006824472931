import { useQuery, gql, QueryHookOptions } from '@apollo/client'

interface AcceptInvite {
  inviteToken: string
  action: 'login' | 'register' | 'accept'
}

interface AcceptInviteResponse {
  accept: AcceptInvite | null
}

const ACCEPT_INVITE_QUERY = gql`
  query AcceptInviteQuery($token: String!) {
    accept(inviteToken: $token) {
      inviteToken
      action
    }
  }
`

export function useAcceptInviteQuery(token: string | null, options?: QueryHookOptions) {
  const { data, loading, error } = useQuery<AcceptInviteResponse | null>(
    ACCEPT_INVITE_QUERY,
    {
      skip: !token,
      variables: { token },
      ...options
    }
  )

  return {
    acceptInvite: data?.accept ?? null,
    loading,
    error
  }
}
