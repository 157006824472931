import { SVGProps } from 'react'

interface MenuSimpleProps extends SVGProps<SVGSVGElement> {
  size?: number
}

export const MenuSimple = ({ size = 16, ...props }: MenuSimpleProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M21 9H3M15 15H3" />
  </svg>
)
export default MenuSimple
