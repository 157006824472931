import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Files as FilesIcon } from 'lucide-react'
import moment from 'moment'
import { Project } from '~/graphql/hooks/useProjectsQuery'
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card'
import Tooltip from '../ui/Tooltip'
import Thumbnail from './Thumbnail'
import cn from '~/utilities/classNames'

interface ProjectCardProps {
  orgId: string
  project: Project
  className?: string
}

const ProjectCard: FC<ProjectCardProps> = ({ orgId, project, className }) => {
  const createdAt = moment(project.createdAt).fromNow()
  return (
    <Link to={`/org/${orgId}/project/${project.id}/reviews`}>
      <Card className={cn('border-divider flex h-48', className)}>
        <div className="w-1/2 flex flex-col overflow-auto">
          <CardHeader>
            <CardTitle className="text-lg leading-5">{project.name}</CardTitle>
            <p className="text-disabled text-sm">{createdAt}</p>
          </CardHeader>
          <CardContent className="mt-auto">
            <div className="flex gap-8">
              <div className="flex items-center gap-1">
                <Tooltip overlay="Reviews Count">
                  <FilesIcon size={16} className="text-disabled" />
                </Tooltip>
                <span className="text-disabled text-sm">{project.jobs.length}</span>
              </div>
            </div>
          </CardContent>
        </div>
        <div className="w-1/2">
          <CardContent className="pt-4 h-full">
            <Thumbnail
              src={project.thumbnail}
              alt={project.name}
              placeholderText="No reviews"
            />
          </CardContent>
        </div>
      </Card>
    </Link>
  )
}

export default ProjectCard
