import { FC } from 'react'
import moment from 'moment'
import ProjectThumbnail from '../Projects/Thumbnail'
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card'
import { formatReviewName } from '~/utilities/stringFormats'
import cn from '~/utilities/classNames'

interface ReviewCardProps {
  name: string
  createdAt: string
  thumbnail?: string | null
  className?: string
  selected?: boolean
}

const ReviewCard: FC<ReviewCardProps> = ({
  name,
  createdAt,
  thumbnail,
  className,
  selected
}) => {
  const created = moment(createdAt).fromNow()
  return (
    <Card
      className={cn(
        'h-40 flex border-0 border-b border-divider rounded-none',
        selected ? 'bg-highlight-bg border-l-4 border-l-highlight' : undefined,
        className
      )}
    >
      <div className="w-1/2 flex flex-col overflow-auto">
        <CardHeader className={selected ? 'pl-5' : 'pl-6'}>
          <CardTitle className="text-base leading-5">{formatReviewName(name)}</CardTitle>
          <p className="text-disabled text-sm">{created}</p>
        </CardHeader>
        <CardContent className="mt-auto">
          <div className="flex items-center gap-1">
            {/* TODO: Uncomment when we decide how we are goint to calculate the number of changes */}
            {/* <p className="text-sm">12 Pages</p>
            <span>•</span>
            <p className="text-sm">17 Changes</p> */}
          </div>
        </CardContent>
      </div>
      <div className="w-1/2">
        <CardContent className="pt-4 h-full">
          <ProjectThumbnail src={thumbnail} alt={name} />
        </CardContent>
      </div>
    </Card>
  )
}

export default ReviewCard
