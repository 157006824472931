import { CheckIcon, XIcon } from 'lucide-react'
import { Toaster as Sonner } from 'sonner'

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      className="toaster group"
      toastOptions={{
        classNames: {
          toast:
            'group toast group-[.toaster]:bg-background group-[.toaster]:text-content group-[.toaster]:border-border group-[.toaster]:shadow-lg',
          description: 'group-[.toast]:text-content-secondary',
          actionButton:
            'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground'
        }
      }}
      position="bottom-center"
      icons={{
        success: <CheckIcon size={16} className="text-success font-semibold" />,
        error: <XIcon size={16} className="text-destructive font-semibold" />
      }}
      {...props}
    />
  )
}

export { Toaster }
