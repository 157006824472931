import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import Loading from '~/components/ui/Loading'
import { useUser } from '~/graphql/hooks/useUser'

const OrgRedirect = () => {
  const [defaultOrg, setDefaultOrg] = useState<number | null>(null)
  const { user } = useUser()

  useEffect(() => {
    if (!user) return

    const isUserOnboarded =
      `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim() !== '' &&
      user?.profileRole !== '' &&
      (user?.orgs.length ?? 0) > 0

    if (!isUserOnboarded) {
      window.location.assign('/onboarding')
    }

    setDefaultOrg(user.orgs[0]?.id)
  }, [user])

  if (defaultOrg) return <Navigate to={`/org/${defaultOrg}/projects`} replace />
  return <Loading />
}

export default OrgRedirect
