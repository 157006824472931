import { useState } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '~/components/ui/Card'
import Dialog, { DialogClose } from '~/components/Dialog'
import Input from '~/components/ui/Input'
import Button from '~/components/ui/Button'
import { LoaderCircle } from 'lucide-react'
import useAdmin from '~/graphql/hooks/useAdmin'

interface CopyToEvalModalParams {
  jobId: number
  isCopying: boolean
  closeDropdown: () => void
  trigger: JSX.Element
}

const copyToEvalSchema = z.object({
  pagesMatch: z.string().trim().optional()
})

type CopyToEvalForm = z.infer<typeof copyToEvalSchema>

const CopyToEval = ({ jobId, trigger, closeDropdown }: CopyToEvalModalParams) => {
  const [isCopying, setCopying] = useState(false)
  const { copyToEval } = useAdmin()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<CopyToEvalForm>({
    resolver: zodResolver(copyToEvalSchema),
    mode: 'onChange'
  })

  const pagesMatchInput = register('pagesMatch')

  const onSubmit = (data: CopyToEvalForm) => {
    setCopying(true)

    copyToEval({
      jobId: jobId,
      pagesMatch: data.pagesMatch ?? ''
    })
      .then(() => {
        toast(
          `Copying job ${jobId} to eval. ${data.pagesMatch && data.pagesMatch.length > 0 && ` for pages: ${data.pagesMatch}`}`
        )
        const event = new CustomEvent('RerunSearch', {})
        window.dispatchEvent(event)
      })
      .catch((e) => {
        console.error('Error copying job to eval:', e)
        toast('Error copying job to eval: ' + e)
      })
      .finally(() => {
        setCopying(false)
        reset()
      })

    closeDropdown()
    setCopying(false)
  }

  return (
    <Dialog
      title="Copy to Eval"
      className="p-0"
      overlay={
        <Card className="border-none shadow-none">
          <CardHeader>
            <CardTitle>Copy Job to Eval</CardTitle>
            <CardDescription>Optionally set a pages to match.</CardDescription>
          </CardHeader>
          <CardContent>
            <form id="copy-to-eval-form" onSubmit={() => void handleSubmit(onSubmit)}>
              <Input
                type="text"
                placeholder="Pages Match (o0r2)"
                {...register('pagesMatch')}
                onChange={(_, event) => void pagesMatchInput.onChange(event)}
                error={errors.pagesMatch?.message}
              />
            </form>
          </CardContent>
          <CardFooter>
            <div className="w-full gap-2 flex justify-end">
              <DialogClose>
                <Button variant="ghost">Cancel</Button>
              </DialogClose>
              <DialogClose>
                <Button type="submit" form="copy-to-eval-form">
                  {isCopying && <LoaderCircle className="mr-2 h-5 w-5 animate-spin" />}
                  Copy
                </Button>
              </DialogClose>
            </div>
          </CardFooter>
        </Card>
      }
    >
      {trigger}
    </Dialog>
  )
}

export default CopyToEval
