// Types will be defined in the ticket https://mbue.atlassian.net/browse/MB-743
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Core, WebViewerInstance } from '@pdftron/webviewer'

import { v4 as uuidv4 } from 'uuid'

import { orderBy } from 'lodash'

export interface TextAnnotationObject {
  type: 'text'
  uuid?: string
  xmin: number
  xmax: number
  ymin: number
  ymax: number
  is_ocr: boolean
  confidence: number
  polygon: [number, number][]
  text: {
    original: string | null
    revised: string | null
  }
  words: {
    added: string[] | null
    removed: string[] | null
  }
}

export interface TableAnnotationObject {
  change_type: string
  original_text: string
  revised_text: string
  polygon: [number, number][]
  original_bbox: {
    xmin: number
    ymin: number
    xmax: number
    ymax: number
  }
  bbox: {
    xmin: number
    ymin: number
    xmax: number
    ymax: number
  }
  uuid: string
  type: string
}

export type Annotation = TextAnnotationObject | TableAnnotationObject

export interface AnnotationPage {
  annotations: Annotation[]
  table_annotations?: TableAnnotationObject[][]
  page_number: number
}

export interface AnnotationPayload {
  pages: AnnotationPage[]
}

export function getTextAnnotationDiffString(original: string, revised: string) {
  const hasText = (text: string) => text !== null && text !== 'None' && text !== ''
  switch (true) {
    case !hasText(revised):
      return `Removed Text\n${original}`
    case !hasText(original):
      return `Added Text\n${revised}`
    default:
      return `Modified\nBefore: ${original}\nNow: ${revised}`
  }
}

export function getTableAnnotationDiffString(annotation: TableAnnotationObject) {
  const hasText = (text: string) => text !== null && text !== 'None' && text !== ''
  switch (true) {
    case annotation.change_type.includes('ADD') || !hasText(annotation.original_text):
      return `Added Text\n${annotation.revised_text}`
    case annotation.change_type.includes('DELETE') || !hasText(annotation.revised_text):
      return `Removed Text\n${annotation.original_text}`
    case annotation.change_type.includes('CHANGE'):
    default:
      return `Modified Text\nBefore: ${annotation.original_text}\nNow: ${annotation.revised_text}`
  }
}

// add uuid to each annotation for easy reference. Needed to match Mbue annotations stored in json with Apryse annotations stored in xfdf.
export function addUuidToAnnotations(
  annotationPayload: AnnotationPayload
): AnnotationPayload {
  return {
    pages: annotationPayload.pages.map((page) => {
      let annotations: any[] = []
      annotations = page.annotations.map((annotation: Annotation) => {
        if (!('uuid' in annotation)) {
          return {
            ...annotation,
            uuid: uuidv4()
          }
        }
        return annotation
      })

      if (page.table_annotations) {
        page.table_annotations.forEach((table: TableAnnotationObject[]) => {
          table.forEach((annotation: any) => {
            if (!('uuid' in annotation)) {
              annotation.uuid = uuidv4()
            }
            annotation.type = 'table'
            annotations.push(annotation)
          })
        })
      }

      return {
        page_number: page.page_number,
        annotations: annotations
      }
    })
  }
}

export function getFillColor(instance: WebViewerInstance, type: string, annotation: any) {
  if (type === 'text') {
    switch (true) {
      case !annotation.text.original:
        return new instance.Core.Annotations.Color(0, 255, 0, 1) // green on add
      case !annotation.text.revised:
        return new instance.Core.Annotations.Color(255, 0, 0, 1) // red on remove
      default:
        return new instance.Core.Annotations.Color(0, 0, 255, 1) // blue on modify
    }
  } else if (type === 'table') {
    const hasText = (text: string) => text !== null && text !== 'None' && text !== ''
    switch (true) {
      case annotation.change_type.includes('ADD') || !hasText(annotation.original_text):
        return new instance.Core.Annotations.Color(0, 255, 0, 1)
      case annotation.change_type.includes('DELETE') || !hasText(annotation.revised_text):
        return new instance.Core.Annotations.Color(255, 0, 0, 1)
      case annotation.change_type.includes('CHANGE'):
      default:
        return new instance.Core.Annotations.Color(0, 0, 255, 1)
    }
  }
  return new instance.Core.Annotations.Color(0, 0, 0, 1)
}

type AnnotationTransformers = {
  text: (
    annotation: TextAnnotationObject,
    instance: WebViewerInstance,
    showOCR: boolean
  ) => Core.Annotations.RectangleAnnotation
  table: (
    annotation: TableAnnotationObject,
    instance: WebViewerInstance
  ) => Core.Annotations.RectangleAnnotation
}

const annotationTransformers: AnnotationTransformers = {
  text: function (
    annotation: TextAnnotationObject,
    instance: WebViewerInstance,
    showOCR: boolean
  ): Core.Annotations.RectangleAnnotation {
    // Text annotations are bboxes acting as highlighter.
    // The tooltip is made in tooltip.tsx and then added in webviewer.tsx

    let textAnnotationOpacity = 1
    const rectAnnotation: Core.Annotations.RectangleAnnotation =
      new instance.Core.Annotations.RectangleAnnotation({})
    textAnnotationOpacity = 0.6
    rectAnnotation.StrokeColor = new instance.Core.Annotations.Color(0, 0, 0, 0)
    rectAnnotation.FillColor = getFillColor(instance, 'text', annotation)
    if (!showOCR) {
      rectAnnotation.Hidden = annotation.confidence < 1
    }
    rectAnnotation.Opacity = textAnnotationOpacity

    const newX = annotation.xmin
    const newY = annotation.ymin
    const newWidth = annotation.xmax - annotation.xmin
    const newHeight = annotation.ymax - annotation.ymin
    rectAnnotation.setX(newX)
    rectAnnotation.setY(newY)
    rectAnnotation.setWidth(newWidth)
    rectAnnotation.setHeight(newHeight)

    rectAnnotation.setContents(
      getTextAnnotationDiffString(
        annotation.text.original ?? '',
        annotation.text.revised ?? ''
      )
    )

    return rectAnnotation
  },

  table: function (
    annotation: TableAnnotationObject,
    instance: WebViewerInstance
  ): Core.Annotations.RectangleAnnotation {
    const rectAnnotation: Core.Annotations.RectangleAnnotation =
      new instance.Core.Annotations.RectangleAnnotation({})
    const tableAnnotationOpacity = 0.6
    rectAnnotation.StrokeColor = new instance.Core.Annotations.Color(0, 0, 0, 0)
    rectAnnotation.FillColor = getFillColor(instance, 'table', annotation)

    rectAnnotation.Opacity = tableAnnotationOpacity
    rectAnnotation.setContents(getTableAnnotationDiffString(annotation))

    const bbox = annotation.bbox ? annotation.bbox : annotation.original_bbox
    const newWidth = bbox.xmax - bbox.xmin
    const newHeight = bbox.ymax - bbox.ymin
    rectAnnotation.setX(bbox.xmin)
    rectAnnotation.setY(bbox.ymin)
    rectAnnotation.setWidth(newWidth)
    rectAnnotation.setHeight(newHeight)

    return rectAnnotation
  }
}

// Transform a JSON annotation into a Apryse WebViewer annotation
export function transformAnnotation(
  annotation: Annotation,
  instance: WebViewerInstance,
  showOCR: boolean
): Core.Annotations.Annotation | null {
  if (!['text', 'table'].includes(annotation.type)) {
    console.error(`No transformer for this ${annotation.type} type found.`)
    return null
  }

  if (annotation.type === 'text') {
    const transformer: AnnotationTransformers['text'] =
      annotationTransformers[annotation.type]
    return transformer(annotation as TextAnnotationObject, instance, showOCR)
  } else if (annotation.type === 'table') {
    const transformer: AnnotationTransformers['table'] =
      annotationTransformers[annotation.type]
    return transformer(annotation, instance)
  }

  return null
}

const sortAnnotations = (annotations: AnnotationPayload): AnnotationPayload => {
  const sortedAnnotations = orderBy(annotations.pages, ['page_number'])
  return {
    pages: sortedAnnotations
  }
}

export const loadAnnotations = async (annotationJsonUri: string) => {
  try {
    const response = await fetch(annotationJsonUri, {
      method: 'GET',
      headers: { Accept: 'application/json' }
    })
    const json = await response.json()
    const sortedAnnotations = sortAnnotations(json)
    const annotations = addUuidToAnnotations(sortedAnnotations)
    return annotations
  } catch (e) {
    console.error('There was an error loading the annotations.', e)
  }
}
