import { datadogRum } from '@datadog/browser-rum'
import { env } from '~/env'

datadogRum.init({
  site: 'us5.datadoghq.com',
  service: 'mbue-frontend',
  applicationId: env.VITE_DATADOG_APPLICATION_ID,
  clientToken: env.VITE_DATADOG_CLIENT_TOKEN,
  env: env.VITE_ENV,
  version: env.VITE_RELEASE_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
})

datadogRum.startSessionReplayRecording()

export default datadogRum
