import Button from '~/components/ui/Button'

const Checkmark = () => (
  <svg
    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
      clipRule="evenodd"
    ></path>
  </svg>
)

export default function Pricing() {
  return (
    <section className="flex w-full flex-col bg-muted/40">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:px-6">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Designed for firms like yours
          </h2>
          <p className="mb-5 font-light text-gray-500 sm:text-lg dark:text-gray-400">
            Here at mbue we focus on utilizing AI to save you time and money while
            reducing liabiilty.
          </p>
        </div>
        <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
          <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
            <h3 className="mb-4 text-2xl font-semibold">Pro</h3>
            <p className="font-light text-gray-500 sm:text-med dark:text-gray-400">
              For sole practitioners and small firms managing a handful of projects per
              month.
            </p>
            <div className="flex justify-center items-baseline my-8">
              <span className="mr-2 text-5xl font-extrabold">$150</span>
              <span className="text-gray-500 dark:text-gray-400">/month</span>
            </div>
            <Button>Contact us</Button>
            <ul role="list" className="my-8 space-y-3 text-left">
              {[
                'Single organization account',
                'Unlimited users',
                'Up to 600 pages reviewed per month or 7,200 pages annually.*',
                '3 active projects included. Add more projects as needed.*',
                'Documents saved in their own project folder',
                'Full AI review functionality',
                'All comment and markup tools',
                'All measure and take-off tools',
                'Invite collaborators',
                'Monthly project usage report for expense reports',
                'Tech support'
              ].map((str) => (
                <li key={str} className="flex items-center space-x-3  text-sm">
                  <Checkmark />
                  <span>{str}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
            <h3 className="mb-4 text-2xl font-semibold">Premium</h3>
            <p className="font-light text-gray-500 sm:text-med dark:text-gray-400">
              For mid-size firms managing multiple projects per month.
            </p>
            <div className="flex justify-center items-baseline my-8">
              <span className="mr-2 text-5xl font-extrabold">$99</span>
              <span className="text-gray-500 dark:text-gray-400">/month</span>
            </div>
            <Button>Contact us</Button>
            <ul role="list" className="my-8 space-y-3 text-left">
              {[
                'Everything in Pro',
                'Onboarding for single organization account',
                'Unlimited users',
                'Unlimited reviews',
                '15 active projects included. Add more projects as needed.*',
                'Premium tech support'
              ].map((str) => (
                <li key={str} className="flex items-center space-x-3 text-sm">
                  <Checkmark />
                  <span>{str}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
            <h3 className="mb-4 text-2xl font-semibold">Enterprise</h3>
            <p className="font-light text-gray-500 sm:text-med dark:text-gray-400">
              For large firms managing multiple projects per month and companies with
              multiple offices.
            </p>
            <div className="flex justify-center items-baseline my-8">
              <span className="text-gray-500 dark:text-gray-400">&nbsp;</span>
            </div>
            <Button>Contact us</Button>
            <ul role="list" className="my-8 space-y-3 text-left">
              {[
                'Everything in Premium',
                'Onboarding for multiple organization accounts',
                'Unlimited users',
                'Unlimited reviews',
                'Unlimited projects',
                'Custom reporting',
                'Dedicated tech support'
              ].map((str) => (
                <li key={str} className="flex items-center space-x-3  text-sm">
                  <Checkmark />
                  <span>{str}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
