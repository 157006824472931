import { FormEvent } from 'react'
import Button from '~/components/ui/Button'
import Input from '~/components/ui/Input'
import Label from '~/components/ui/Label'

interface EmailLoginSuccessProps {
  onResendEmail: (event: FormEvent<HTMLFormElement>) => void
  email: string
}

export const EmailLoginSuccess = ({ email, onResendEmail }: EmailLoginSuccessProps) => {
  return (
    <form className="grid gap-4" onSubmit={onResendEmail}>
      <div className="grid gap-2">
        <Label htmlFor="email">We sent a verification email to</Label>
        <Input
          id="email"
          type="email"
          name="email"
          onChange={() => ({})}
          value={email}
          readOnly
        />
      </div>
      <div className="mt-4 text-center text-sm">
        Not seeing the email?
        <Button type="submit" variant="link">
          Resend
        </Button>
      </div>
    </form>
  )
}
