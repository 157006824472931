import * as React from 'react'
import { ChevronLeft, ChevronRight, MoreHorizontal } from 'lucide-react'

import { ButtonProps, buttonVariants } from '~/components/ui/Button'
import classNames from '~/utilities/classNames'

interface HTMLElementWithClassName<T extends HTMLElement>
  extends React.HTMLAttributes<T> {
  className?: string
}

const Pagination = React.forwardRef<
  HTMLDivElement,
  HTMLElementWithClassName<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <nav
    role="navigation"
    ref={ref}
    aria-label="pagination"
    className={classNames('mx-auto flex w-full justify-center', className)}
    {...props}
  />
))
Pagination.displayName = 'Pagination'

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  HTMLElementWithClassName<HTMLUListElement>
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={classNames('flex flex-row items-center gap-1', className)}
    {...props}
  />
))
PaginationContent.displayName = 'PaginationContent'

const PaginationItem = React.forwardRef<
  HTMLLIElement,
  HTMLElementWithClassName<HTMLLIElement>
>(({ className, ...props }, ref) => (
  <li ref={ref} className={classNames('', className)} {...props} />
))
PaginationItem.displayName = 'PaginationItem'

type PaginationLinkProps = {
  isActive?: boolean
} & Pick<ButtonProps, 'size'> &
  React.ComponentProps<'a'>

const PaginationLink = ({
  className,
  isActive,
  size = 'icon',
  ...props
}: PaginationLinkProps) => (
  <a
    aria-current={isActive ? 'page' : undefined}
    className={classNames(
      buttonVariants({
        variant: isActive ? 'outline' : 'ghost',
        size
      }),
      className
    )}
    {...props}
  />
)
PaginationLink.displayName = 'PaginationLink'

const PaginationPrevious = React.forwardRef<
  HTMLAnchorElement,
  HTMLElementWithClassName<HTMLAnchorElement>
>(({ className, ...props }, ref) => (
  <PaginationLink
    ref={ref}
    aria-label="Go to previous page"
    size="default"
    className={classNames('gap-1 pl-2.5', className)}
    {...props}
  >
    <ChevronLeft className="h-4 w-4" />
    <span>Previous</span>
  </PaginationLink>
))
PaginationPrevious.displayName = 'PaginationPrevious'

const PaginationNext = React.forwardRef<
  HTMLAnchorElement,
  HTMLElementWithClassName<HTMLAnchorElement>
>(({ className, ...props }, ref) => (
  <PaginationLink
    ref={ref}
    aria-label="Go to next page"
    size="default"
    className={classNames('gap-1 pr-2.5', className)}
    {...props}
  >
    <span>Next</span>
    <ChevronRight className="h-4 w-4" />
  </PaginationLink>
))
PaginationNext.displayName = 'PaginationNext'

const PaginationEllipsis = React.forwardRef<
  HTMLAnchorElement,
  HTMLElementWithClassName<HTMLAnchorElement>
>(({ className, ...props }, ref) => (
  <span
    ref={ref}
    aria-hidden
    className={classNames('flex h-9 w-9 items-center justify-center', className)}
    {...props}
  >
    <MoreHorizontal className="h-4 w-4" />
    <span className="sr-only">More pages</span>
  </span>
))
PaginationEllipsis.displayName = 'PaginationEllipsis'

export {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious
}
