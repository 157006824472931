import { useState } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '~/components/ui/Card'
import Dialog, { DialogClose } from '~/components/Dialog'
import Input from '~/components/ui/Input'
import Button from '~/components/ui/Button'
import { LoaderCircle } from 'lucide-react'
import useAdmin from '~/graphql/hooks/useAdmin'

interface RerunJobModalParams {
  jobId: number
  isRerunning: boolean
  closeDropdown: () => void
  trigger: JSX.Element
}

const rerunJobSchema = z.object({
  containerTag: z.string().trim().optional()
})

type RerunJobForm = z.infer<typeof rerunJobSchema>

const RerunJob = ({ jobId, trigger, closeDropdown }: RerunJobModalParams) => {
  const [isRerunning, setIsRerunning] = useState(false)
  const { rerunJob } = useAdmin()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<RerunJobForm>({
    resolver: zodResolver(rerunJobSchema),
    mode: 'onChange'
  })

  const containerTagInput = register('containerTag')

  const onSubmit = (data: RerunJobForm) => {
    setIsRerunning(true)

    rerunJob({
      jobId: jobId,
      containerTag: data.containerTag ?? ''
    })
      .then(() => {
        toast(
          `Rerunning job ${jobId}. ${data.containerTag && data.containerTag.length > 0 && ` with containerTag: ${data.containerTag}`}`
        )
        const event = new CustomEvent('RerunSearch', {})
        window.dispatchEvent(event)
      })
      .catch((e) => {
        console.error('Error creating project:', e)
        toast('Error creating project: ' + e)
      })
      .finally(() => {
        setIsRerunning(false)
        reset()
      })

    closeDropdown()
    setIsRerunning(false)
  }

  return (
    <Dialog
      title="Rerun Job"
      className="p-0"
      overlay={
        <Card className="border-none shadow-none">
          <CardHeader>
            <CardTitle>Rerun Job</CardTitle>
            <CardDescription>Optionally set a branch.</CardDescription>
          </CardHeader>
          <CardContent>
            <form
              id="rerun-job-form"
              onSubmit={(event) => void handleSubmit(onSubmit)(event)}
            >
              <Input
                type="text"
                placeholder="Container Tag"
                {...register('containerTag')}
                onChange={(_, event) => void containerTagInput.onChange(event)}
                error={errors.containerTag?.message}
              />
            </form>
          </CardContent>
          <CardFooter>
            <div className="w-full gap-2 flex justify-end">
              <DialogClose>
                <Button variant="ghost">Cancel</Button>
              </DialogClose>
              <DialogClose>
                <Button type="submit" form="rerun-job-form">
                  {isRerunning && <LoaderCircle className="mr-2 h-5 w-5 animate-spin" />}
                  Rerun
                </Button>
              </DialogClose>
            </div>
          </CardFooter>
        </Card>
      }
    >
      {trigger}
    </Dialog>
  )
}

export default RerunJob
