import { createEnv } from '@t3-oss/env-core'
import { z } from 'zod'

export const env = createEnv({
  clientPrefix: 'VITE_',
  client: {
    VITE_ENV: z.enum(['development', 'staging', 'prod']),
    VITE_API_ENDPOINT: z.string().url().min(1),
    VITE_DOMAIN: z.string().min(1),
    VITE_SITE_URL: z.string().url().min(1),
    VITE_COOKIE_NAME: z.string().min(1),
    VITE_WEBVIEWER_TOKEN: z.string().min(1).optional(),
    VITE_WEBVIEWER_DEMO_TOKEN: z.string().min(1),
    VITE_SHOW_FEEDBACK: z.enum(['true', 'false']).transform((val) => val === 'true'),
    VITE_STRIPE_PUBLIC_KEY: z.string().min(1),
    VITE_STRIPE_PRICING_TABLE: z.string().min(1),
    VITE_MIXPANEL_TOKEN: z.string().min(1),
    VITE_TOS_URL: z.string().min(1),
    VITE_PP_URL: z.string().min(1),
    VITE_CUSTOM_UI: z.enum(['true', 'false']).transform((val) => val === 'true'),
    VITE_DATADOG_APPLICATION_ID: z.string().min(1),
    VITE_DATADOG_CLIENT_TOKEN: z.string().min(1),
    VITE_GOOGLE_OAUTH_CLIENT_ID: z.string().min(1),
    VITE_RELEASE_VERSION: z.string().min(1),
    VITE_DEVCYCLE_KEY: z.string().min(1)
  },
  runtimeEnv: import.meta.env,
  emptyStringAsUndefined: true
})
