import { useState, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import {
  ScanSearchIcon,
  TextCursorIcon,
  DraftingCompassIcon,
  PencilRulerIcon,
  ChevronUpIcon,
  ChevronDownIcon
} from 'lucide-react'
import { classNames } from '~/utilities'

import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableHeader
} from '~/components/ui/Table'
import Badge, { BadgeProps } from '~/components/ui/Badge'

interface ReportLine {
  changeId: number
  discipline: string
  sheetNumber: string
  sheetTile: string
  preview: string
  impact: string
  description: string
  type: string
}

const jobReportMock = [
  {
    changeId: 1,
    discipline: 'One value',
    sheetNumber: 'A1.1',
    sheetTile: 'Building Plans',
    preview: 'http://localhost:4300/org/185/project/615/reviews/4899/view',
    impact: 'low',
    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit',
    type: 'text'
  },
  {
    changeId: 2,
    discipline: 'Another value',
    sheetNumber: 'A1.2',
    sheetTile: 'Building Plans',
    preview: 'http://localhost:4300/org/185/project/615/reviews/4899/view',
    impact: 'medium',
    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit',
    type: 'graphic'
  },
  {
    changeId: 3,
    discipline: 'Third value',
    sheetNumber: 'A1.3',
    sheetTile: 'Building Plans',
    preview: 'http://localhost:4300/org/185/project/615/reviews/4899/view',
    impact: 'high',
    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit',
    type: 'both'
  },
  {
    changeId: 4,
    discipline: 'Forth value',
    sheetNumber: 'A1.1',
    sheetTile: 'Building Plans',
    preview: 'http://localhost:4300/org/185/project/615/reviews/4899/view',
    impact: 'medium',
    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit',
    type: 'graphic'
  }
]

const HeaderCell = ({
  children,
  column
}: PropsWithChildren<{ column: Column<ReportLine, unknown> }>): JSX.Element => {
  return (
    <button
      className="w-full"
      onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
    >
      <div className="flex items-center gap-1 justify-center">
        <span>{children}</span>
        <div className="flex flex-col justify-center items-center">
          <ChevronUpIcon
            size={13}
            className={`${column.getIsSorted() === 'asc' ? 'text-black' : 'text-disabled'} translate-y-[2px]`}
          />
          <ChevronDownIcon
            size={13}
            className={`${column.getIsSorted() === 'desc' ? 'text-black' : 'text-disabled'} -translate-y-[2px]`}
          />
        </div>
      </div>
    </button>
  )
}

const Cell = ({
  className,
  children
}: PropsWithChildren<{ className?: string }>): JSX.Element => (
  <div
    className={classNames('w-full max-w-[150px] overflow-hidden text-nowrap', className)}
  >
    {children}
  </div>
)

const columns: ColumnDef<ReportLine>[] = [
  {
    accessorKey: 'changeId',
    header: ({ column }) => {
      return <HeaderCell column={column}>Id</HeaderCell>
    },
    cell: (info) => {
      const value = info.getValue() as string
      return <Cell className="text-center">{value}</Cell>
    },
    maxSize: 40
  },
  {
    accessorKey: 'discipline',
    header: ({ column }) => {
      return <HeaderCell column={column}>Discipline</HeaderCell>
    },
    cell: (info) => {
      const value = info.getValue() as string
      return <Cell>{value}</Cell>
    },
    maxSize: 100
  },
  {
    accessorKey: 'sheetNumber',
    header: ({ column }) => {
      return <HeaderCell column={column}>Sheet #</HeaderCell>
    },
    cell: (info) => {
      const value = info.getValue() as string
      return <Cell className="text-center">{value}</Cell>
    },
    maxSize: 100
  },
  {
    accessorKey: 'sheetTile',
    header: ({ column }) => {
      return <HeaderCell column={column}>Sheet Title</HeaderCell>
    },
    cell: (info) => {
      const value = info.getValue() as string
      return <Cell>{value}</Cell>
    },
    maxSize: 150
  },
  {
    accessorKey: 'preview',
    header: 'Preview',
    cell: (info) => {
      const value = info.getValue() as string
      return (
        <Cell className="max-w-full flex justify-center">
          <Link to={value}>
            <ScanSearchIcon size={22} className="text-primary" />
          </Link>
        </Cell>
      )
    },
    maxSize: 75
  },
  {
    accessorKey: 'impact',
    header: ({ column }) => {
      return <HeaderCell column={column}>Impact</HeaderCell>
    },
    cell: (info) => {
      const value = info.getValue() as string
      return (
        <Cell className="flex justify-center">
          {' '}
          <Badge
            {...(getImpact(value) as BadgeProps)}
            className="w-20 flex justify-center"
          />
        </Cell>
      )
    },
    maxSize: 80
  },
  {
    accessorKey: 'description',
    header: 'Description',
    cell: (info) => {
      const value = info.getValue() as string
      return <Cell className="max-w-full truncate">{value}</Cell>
    },
    maxSize: 250
  },
  {
    accessorKey: 'type',
    header: ({ column }) => {
      return <HeaderCell column={column}>Type</HeaderCell>
    },
    cell: (info) => {
      const value = info.getValue() as string
      return (
        <Cell className="w-full flex items-center gap-1">
          {value === 'graphic' && <DraftingCompassIcon size={14} />}
          {value === 'text' && <TextCursorIcon size={14} />}
          {value === 'both' && <PencilRulerIcon size={14} />}
          <span className="capitalize">{value}</span>
        </Cell>
      )
    },
    maxSize: 80
  }
]

const SmartReporting = () => {
  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data: jobReportMock,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting
    }
  })

  return (
    <div className="w-full rounded-xl border pb-2">
      <Table>
        <TableHeader>
          <TableRow>
            {table.getHeaderGroups()[0].headers.map((header) => {
              return (
                <TableHead
                  key={header.id}
                  style={{ maxWidth: `${header.column.columnDef.maxSize}px` }}
                  className="text-center"
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              )
            })}
          </TableRow>
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => {
            return (
              <TableRow key={row.id} className="hover:bg-fundation-emphasis">
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell
                      key={cell.id}
                      className="overflow-x-hidden"
                      style={{ maxWidth: `${cell.column.columnDef.maxSize}px` }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default SmartReporting

function getImpact(impact: string): { label: string; variant: string } {
  if (impact === 'high') return { label: 'High', variant: 'error' }
  if (impact === 'medium') return { label: 'Medium', variant: 'warning' }
  return { label: 'Low', variant: 'success' }
}
