import { useMutation, gql } from '@apollo/client'
import { MutationHookParams } from '../sharedTypes/types'

export interface InviteUserResponse {
  invite: boolean
}

const INVITE_USER_MUTATION = gql`
  mutation InviteUserMutation($orgId: Int!, $emails: String!, $role: String) {
    invite(orgId: $orgId, emails: $emails, role: $role)
  }
`

export const useInviteUserMutation = (options?: MutationHookParams) => {
  const [mutation, { data, loading, error }] = useMutation<InviteUserResponse>(
    INVITE_USER_MUTATION,
    options
  )

  const inviteUser = async (orgId: number, emails: string, role: string) => {
    const response = await mutation({
      variables: { orgId, emails, role }
    })
    return response.data?.invite ?? false
  }

  return {
    inviteUser,
    data,
    loading,
    error
  }
}
