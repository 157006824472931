import { useState, useCallback, MouseEvent } from 'react'
import { useParams } from 'react-router'
import { useDropzone, FileRejection } from 'react-dropzone'
import { X as CloseIcon, LoaderCircleIcon } from 'lucide-react'
import cn from '~/utilities/classNames'
import Button from '../ui/Button'
import {
  useCreateDesignMutation,
  type CreateDesign
} from '~/graphql/hooks/useCreateDesignMutation'

export interface OnUploadFileParams {
  error?: string[]
  data?: CreateDesign
  name: string
}

interface DocumentDropZoneProps {
  name: string
  label: string
  onUploadFile: (params: OnUploadFileParams) => void
}

export default function DocumentDropZone({
  label,
  onUploadFile,
  name
}: DocumentDropZoneProps) {
  const { projectId } = useParams<{ projectId: string }>()
  const [file, setFile] = useState<File | null>(null)

  const { createDesign, loading: designUploading } = useCreateDesignMutation()

  const onDropFiles = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0])
        const error = []

        const [acceptedFile] = acceptedFiles

        if (acceptedFile.size > 1000 * 1000000) {
          error.push('File is too big')
        }

        if (error.length > 0) {
          onUploadFile({ name, error })
          return
        }

        const designPayload = {
          projectId: parseInt(projectId!),
          s3Key: 'document.pdf',
          originalFilename: acceptedFiles[0].name,
          pageCount: 1,
          fileSizeBytes: acceptedFiles[0].size
        }

        const design = await createDesign(designPayload, acceptedFiles[0])
        onUploadFile({
          name,
          data: design
        })
      }

      if (fileRejections.length > 0) {
        const { errors } = fileRejections[0]
        const error = errors.map((e) => e.message)
        onUploadFile({
          name,
          error
        })
      }
    },
    [createDesign, name, onUploadFile, projectId]
  )

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    maxFiles: 1,
    accept: {
      'application/pdf': ['.pdf']
    },
    onDrop: (acceptedFiles, fileRejections) =>
      void onDropFiles(acceptedFiles, fileRejections),
    noDragEventsBubbling: true,
    noClick: true
  })

  const onRemoveFile = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setFile(null)
  }

  return (
    <div
      {...getRootProps()}
      className={cn(
        'grid justify-items-center items-center w-full h-full border border-dashed border-divider rounded-md',
        isDragActive ? 'border-action' : ''
      )}
    >
      {designUploading && (
        <div>
          <LoaderCircleIcon size={32} className="text-action animate-spin" />
        </div>
      )}

      {!designUploading && (
        <div className="flex flex-col justify-center">
          <input {...getInputProps()} />
          <p className="text-sm text-center font-medium">{label}</p>
          <div className="flex items-center gap-1">
            {!file ? (
              <>
                <Button
                  type="button"
                  variant="link"
                  onClick={open}
                  className="inline p-0 h-fit"
                >
                  Select
                </Button>
                <span className="text-sm">or drop</span>
              </>
            ) : (
              <>
                <p className="text-sm text-content-secondary-hover overflow-hidden text-nowrap max-w-56 truncate">
                  {file.name}
                </p>
                <button
                  type="button"
                  className="border border-divider rounded-full"
                  onClick={onRemoveFile}
                >
                  <CloseIcon size={16} />
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
