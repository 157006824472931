import { LoaderCircle } from 'lucide-react'
import { Link } from 'react-router-dom'
import { GoogleLogin, CredentialResponse } from '@react-oauth/google'

import Button from '~/components/ui/Button'
import Input from '~/components/ui/Input'
import Label from '~/components/ui/Label'

interface LoginFormParams {
  email: string
  setEmail: (email: string) => void
  password: string
  setPassword: (password: string) => void
  isPasswordEnabled: boolean
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  onGoogleLoginSuccess: (credentialResponse: CredentialResponse) => void
  loading?: boolean
}

export const LoginForm = ({
  email,
  setEmail,
  password,
  setPassword,
  isPasswordEnabled,
  onSubmit,
  onGoogleLoginSuccess,
  loading
}: LoginFormParams) => {
  return (
    <>
      <form className="grid gap-4" onSubmit={(event) => void onSubmit(event)}>
        <div className="grid gap-2">
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            type="email"
            name="email"
            placeholder="email@example.com"
            onChange={setEmail}
            value={email}
            required
          />
        </div>
        {isPasswordEnabled && (
          <div className="grid gap-2">
            <div className="flex items-center">
              <Label htmlFor="password">Password</Label>
              <Link
                to="/forgot-password"
                className="ml-auto inline-block text-sm underline"
              >
                Forgot your password?
              </Link>
            </div>
            <Input
              id="password"
              type="password"
              name="password"
              required={isPasswordEnabled}
              onChange={setPassword}
              value={password}
            />
          </div>
        )}
        <Button type="submit" className="w-full" disabled={loading}>
          {loading && <LoaderCircle className="mr-2 h-5 w-5 animate-spin" />}
          Login
        </Button>
      </form>
      <p className="text-center">or</p>
      <div className="grid gap-4">
        <GoogleLogin
          onSuccess={onGoogleLoginSuccess}
          onError={() => {
            console.error('Authentication with google has failed.')
          }}
          width="350"
          cancel_on_tap_outside
        />
        <div className="w-full text-center">
          <p className="text-xs">
            I agree to{' '}
            <a
              href="https://docsend.com/view/b6uajeme9jrr4w7t"
              target="_blank"
              rel="noreferrer"
              className="text-primary underline underline-offset-2"
            >
              terms of service
            </a>{' '}
            and{' '}
            <a
              href="https://docsend.com/view/598zsppf6n9vc5r7"
              target="_blank"
              rel="noreferrer"
              className="text-primary underline underline-offset-2"
            >
              privacy statements
            </a>
          </p>
        </div>
      </div>
      <div className="mt-4 text-center text-sm flex flex-col gap-2 items-center">
        <span>
          Don&apos;t have an account?{' '}
          <Link to={`/register?password=${isPasswordEnabled}`} className="underline">
            Sign up
          </Link>
        </span>
        <Link
          to={`/login?password=${!isPasswordEnabled}`}
          className="text-transparent w-fit"
        >
          Login With Password
        </Link>
      </div>
    </>
  )
}
