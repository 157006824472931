import { Dispatch, PropsWithChildren, SetStateAction, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import * as z from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  useUpdateProjectMutation,
  UpdateProjectInput
} from '~/graphql/hooks/useUpdateProjectMutation'

import { LoaderCircle } from 'lucide-react'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '~/components/ui/Card'
import Dialog, { DialogClose } from '~/components/Dialog'
import Input from '~/components/ui/Input'
import Button from '~/components/ui/Button'

type ProjectSettingsDialog = PropsWithChildren<{
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  projectName?: string
}>

const updateProjectSchema = z.object({
  projectName: z.string().trim().min(1, { message: 'Project name is required.' })
})

const ProjectSettingsDialog = ({
  children,
  open,
  setOpen,
  projectName = ''
}: ProjectSettingsDialog) => {
  const { projectId } = useParams<{ projectId: string }>()

  const { updateProject, loading: updateProjectLoading } = useUpdateProjectMutation()

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<z.infer<typeof updateProjectSchema>>({
    resolver: zodResolver(updateProjectSchema)
  })

  const onSave = async (form: z.infer<typeof updateProjectSchema>) => {
    const updateProjectInput: UpdateProjectInput = {
      id: parseInt(projectId!),
      name: form.projectName
    }

    try {
      const response = await updateProject(updateProjectInput)
      if (response.data?.updateProject?.id) {
        setOpen(false)
      }
    } catch (error: unknown) {
      console.error(error)
    }
  }

  useEffect(() => {
    setValue('projectName', projectName)
    return () => reset()
  }, [projectName, reset, setValue])

  const projectNameInput = register('projectName')
  return (
    <Dialog
      open={open}
      title="Project Settings"
      className="p-0"
      onOpenChange={setOpen}
      overlay={
        <Card className="border-0 shadow-none">
          <CardHeader>
            <CardTitle>Rename Project</CardTitle>
            <CardDescription>Enter a new name for your project.</CardDescription>
          </CardHeader>
          <CardContent>
            <form
              id="project-settings-dialog"
              onSubmit={(event) => void handleSubmit(onSave)(event)}
            >
              <Input
                {...projectNameInput}
                onChange={(_, event) => void projectNameInput.onChange(event)}
                error={errors.projectName?.message}
              />
            </form>
          </CardContent>
          <CardFooter>
            <div
              className="w-full gap-2 flex justify-end"
              onClick={(e) => e.stopPropagation()}
            >
              <DialogClose>
                <Button type="button" variant="ghost">
                  Cancel
                </Button>
              </DialogClose>

              <Button
                type="submit"
                form="project-settings-dialog"
                disabled={updateProjectLoading}
              >
                {updateProjectLoading && (
                  <LoaderCircle className="mr-2 h-5 w-5 animate-spin" />
                )}
                Save
              </Button>
            </div>
          </CardFooter>
        </Card>
      }
    >
      {children}
    </Dialog>
  )
}

export default ProjectSettingsDialog
