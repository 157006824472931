import { Outlet } from 'react-router'
import TopNav from '~/components/TopNav'

export default function Layout() {
  return (
    <>
      <TopNav />
      <main className="min-h-screen-header">
        <Outlet />
      </main>
    </>
  )
}
