import { gql, useQuery, QueryHookOptions } from '@apollo/client'

export interface Member {
  id: number
  memberId: number
  invitationId: number | null
  name: string
  email: string
  role: string
  status: string | number
  createdAt: string
}

export interface OrgMembersResponse {
  orgMembers: Member[]
}

const ORG_MEMBERS_QUERY = gql`
  query OrgMembersQuery($orgId: Int!) {
    orgMembers(orgId: $orgId) {
      id
      memberId
      invitationId
      name
      email
      role
      status
      createdAt
    }
  }
`

export const useOrgMembersQuery = (orgId: number, options?: QueryHookOptions) => {
  const { data, loading, error, refetch } = useQuery<OrgMembersResponse>(
    ORG_MEMBERS_QUERY,
    {
      variables: { orgId },
      ...options
    }
  )

  const refetchOrgMembers = (orgId?: number) => {
    if (orgId) {
      return refetch({
        orgId
      })
    }

    void refetch()
  }

  return {
    members: data?.orgMembers ?? [],
    loading,
    error,
    refetchOrgMembers
  }
}

export default useOrgMembersQuery
