import { FC, PropsWithChildren } from 'react'
import { env } from '~/env.ts'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as Provider,
  createHttpLink,
  ApolloLink,
  Operation,
  NextLink,
  FetchResult,
  Observable
} from '@apollo/client'
import datadogRum from '~/utilities/datadog'
import { setContext } from '@apollo/client/link/context'

interface PrevContext {
  headers?: Record<string, string>
}

const httpLink = createHttpLink({ uri: env.VITE_API_ENDPOINT })

type SafeFetchResult = FetchResult<
  Record<string, unknown>,
  Record<string, unknown>,
  Record<string, unknown>
>

const datadogLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  const startTime = Date.now()

  return new Observable<SafeFetchResult>((observer) => {
    const subscription = forward(operation).subscribe({
      next(response: SafeFetchResult) {
        const duration = Date.now() - startTime

        datadogRum.addAction('graphql-operation', {
          name: operation.operationName || 'unnamed operation',
          duration,
          status: response.errors ? 'error' : 'success'
        })

        if (response.errors) {
          response.errors.forEach((err) => {
            datadogRum.addError(new Error(`GraphQL Error: ${err.message}`), {
              operationName: operation.operationName || 'unnamed operation',
              errorInfo: err
            })
          })
        }
        observer.next(response)
      },
      error(error: Error) {
        datadogRum.addError(error, {
          operationName: operation.operationName || 'unnamed operation'
        })
        observer.error(error)
      },
      complete() {
        observer.complete()
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  })
})

const authLink = setContext((_operation, { headers }: PrevContext) => {
  const token = JSON.parse(localStorage.getItem('access_token') ?? '') as string
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const client = new ApolloClient({
  link: ApolloLink.from([datadogLink, authLink, httpLink]),
  cache: new InMemoryCache()
})

export const ApolloProvider: FC<PropsWithChildren> = ({ children }) => {
  return <Provider client={client}>{children}</Provider>
}
