import { useMutation, gql } from '@apollo/client'
import { MutationHookParams } from '../sharedTypes/types'

export interface CreateProjectResponse {
  createProject: {
    id: number
    name: string
    orgId: string
    orgName: string
  } | null
}

export interface CreateProjectInput {
  name: string
  orgId: number
}

const CREATE_PROJECT_MUTATION = gql`
  mutation ($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
      name
      orgId
      orgName
    }
  }
`

const useCreateProjectMutation = (options?: MutationHookParams) => {
  const [mutation, { data, loading, error }] = useMutation<CreateProjectResponse>(
    CREATE_PROJECT_MUTATION,
    options
  )

  const createProject = (input: CreateProjectInput) =>
    mutation({
      variables: { input }
    })

  return {
    createProject,
    data,
    loading,
    error
  }
}

export default useCreateProjectMutation
