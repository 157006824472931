import { SVGProps } from 'react'

interface DrawingIconProps extends SVGProps<SVGSVGElement> {
  size?: number
}

const DrawingIcon = ({ size = 42, ...props }: DrawingIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox={`0 0 42 42`}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      stroke="currentColor"
      d="M41.11 41H7.685C3.992 41 1 37.745 1 33.727V5.2C1.518 3.236 3.14 1 6.014 1c3.108 0 4.63 2.436 4.997 4.2v3.073H41.11V41Zm-1.671-30.91H11.027v23.637S9.925 31.91 7.685 31.91c-1.838 0-3.343 1.636-3.343 3.636s1.505 3.637 3.343 3.637h31.754V10.09ZM9.356 5.219c-.718-1.763-2.223-2.418-3.342-2.4-1.103 0-2.624.546-3.343 2.4v29.418c.652-3.618 3.744-5.236 6.685-4.363V5.218Zm25.069 28.51H14.37V15.544h20.055v18.182Zm-13.37-16.364H16.04v14.545h16.713v-7.273H29.41v5.455H27.74v-5.455h-5.014v5.455h-1.671V17.364Zm11.699 0H22.726v5.454h10.028v-5.454Z"
      clipRule="evenodd"
    />
  </svg>
)
export default DrawingIcon
