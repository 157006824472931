import { PropsWithChildren, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  useCreateJobMutation,
  JobType,
  MatchType
} from '~/graphql/hooks/useCreateJobMutation'

import { FileTextIcon, LoaderCircleIcon } from 'lucide-react'
import { Switch } from '../ui/Switch'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '~/components/ui/Dialog'
import DrawingIcon from '../ui/icons/Drawing'
import Button from '../ui/Button'
import Label from '../ui/Label'
import Input from '../ui/Input'
import DocumentDropZone, { type OnUploadFileParams } from './DocumentDropZone'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '../ui/AlertDialog'

interface NewReviewDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const createReviewSchema = z.object({
  jobType: z.nativeEnum(JobType),
  originalDesignId: z.number().int(),
  revisedDesignId: z.number().int(),
  name: z.string().trim().min(1)
})

type CreateReview = z.infer<typeof createReviewSchema>

export default function NewReviewDialog({ open, setOpen }: NewReviewDialogProps) {
  const navigate = useNavigate()
  const confirmButtonRef = useRef<HTMLButtonElement>(null)
  const { orgId, projectId } = useParams<{ orgId: string; projectId: string }>()
  const [isReviewNameDisabled, setIsReviewNameDisabled] = useState<boolean>(true)

  const { createJob, loading: reviewLoading } = useCreateJobMutation()

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    setError,
    reset,
    clearErrors,
    formState: { errors }
  } = useForm<CreateReview>({
    defaultValues: {
      jobType: JobType.drawing,
      name: ''
    },
    resolver: zodResolver(createReviewSchema)
  })

  const onUploadFile = (uploadedFile: OnUploadFileParams) => {
    const { name, error, data } = uploadedFile

    const docType = name as Parameters<typeof setError>[0] &
      Parameters<typeof setValue>[0]

    clearErrors(docType)

    if (error) {
      setError(docType, {
        message: error.join('\n')
      })
      return
    }

    if (data) {
      if (docType === 'revisedDesignId') {
        setValue('name', data.originalFilename, {
          shouldTouch: true,
          shouldValidate: true
        })
        setIsReviewNameDisabled(false)
      }
      setValue(docType, data.id)
    }
  }

  const onReviewTypeChange = (checked: boolean) => {
    const mapCheckedToJobType: Record<string, number> = {
      false: 1,
      true: 2
    }
    const selectedReviewType = mapCheckedToJobType[checked.toString()]
    setValue('jobType', selectedReviewType, { shouldTouch: true, shouldValidate: true })
  }

  const onSubmit = async (formData: CreateReview) => {
    const reviewPayload = {
      projectId: parseInt(projectId!),
      ...formData,
      matchType: MatchType.auto
    }

    const jobResponse = await createJob(reviewPayload)

    if (jobResponse?.id) {
      confirmButtonRef?.current?.click()
      reset()
      navigate(`/org/${orgId}/project/${projectId}/reviews/${jobResponse.id}`)
      setOpen(false)
    }
  }

  const handleModalOpenChange = (open: boolean) => {
    if (!open) reset()
    setOpen(false)
  }

  const fileNameInput = register('name')

  return (
    <>
      <Dialog onOpenChange={handleModalOpenChange} open={open}>
        <DialogContent className="min-w-[660px]">
          <DialogHeader>
            <DialogTitle>New Review</DialogTitle>
            <DialogDescription className="sr-only">Create a new review</DialogDescription>
          </DialogHeader>
          <form
            id="create-job-form"
            onSubmit={(event) => void handleSubmit(onSubmit)(event)}
          >
            <div className="grid grid-cols-2 grid-rows-[80px_160px] gap-4">
              <div className="self-end grid gap-1">
                <Label htmlFor="name" className="text-sm">
                  Title
                </Label>
                <Input
                  id="name"
                  {...fileNameInput}
                  onChange={(_, event) => void fileNameInput.onChange(event)}
                  error={errors.name?.message}
                  disabled={isReviewNameDisabled}
                />
              </div>

              <div className="flex gap-4 items-center justify-center self-end">
                <div className="grid gap-1 justify-center">
                  <DrawingIcon
                    className={`mx-auto ${watch('jobType') === JobType.drawing ? 'text-highlight' : 'text-placeholder'}`}
                  />
                  <p className="text-sm text-content-secondary font-semibold m0">
                    Drawing Mode
                  </p>
                </div>
                <Switch
                  className="data-[state=checked]:bg-content-secondary"
                  onCheckedChange={onReviewTypeChange}
                />
                <div className="grid gap-1 justify-center">
                  <FileTextIcon
                    size={42}
                    className={`mx-auto ${watch('jobType') === JobType.text ? 'text-highlight' : 'text-placeholder'}`}
                  />
                  <p className="text-sm text-content-secondary font-semibold">
                    Text Mode
                  </p>
                </div>
              </div>
              <div className="w-full flex flex-col gap-1">
                <DocumentDropZone
                  label="Original File"
                  onUploadFile={onUploadFile}
                  name="originalDesignId"
                />
                {errors.originalDesignId && (
                  <p className="text-error text-xs">{errors.originalDesignId?.message}</p>
                )}
              </div>
              <div className="w-full flex flex-col gap-1">
                <DocumentDropZone
                  label="New File"
                  onUploadFile={onUploadFile}
                  name="revisedDesignId"
                />
                {errors.revisedDesignId && (
                  <p className="text-error text-xs">{errors.revisedDesignId?.message}</p>
                )}
              </div>
            </div>
          </form>
          <DialogFooter>
            <div className="flex gap-4">
              <Button
                variant="secondary"
                onClick={() => {
                  reset()
                  setOpen(false)
                }}
              >
                Cancel
              </Button>

              <Button type="submit" form="create-job-form" disabled={reviewLoading}>
                {reviewLoading && (
                  <LoaderCircleIcon className="mr-2 h-5 w-5 animate-spin" />
                )}
                Start Review
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <ConfirmDialog>
        <button ref={confirmButtonRef} className="hidden" />
      </ConfirmDialog>
    </>
  )
}

export function ConfirmDialog({ children }: PropsWithChildren) {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>All Done!</AlertDialogTitle>
          <AlertDialogDescription>
            We will take it from here. You will receive an email when the comparison is
            complete. You can close your browser, get some coffee, and come back, or click
            &apos;Close&apos; to start another review while you wait.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction>Close</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
