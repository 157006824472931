import classNames from '~/utilities/classNames'

function Skeleton({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames('animate-pulse rounded-md bg-gray-500/5', className)}
      {...props}
    />
  )
}

export default Skeleton
