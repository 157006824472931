import { useEffect, useState } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '~/components/ui/Card'
import Dialog, { DialogClose } from '~/components/Dialog'
import Input from '~/components/ui/Input'
import Button from '~/components/ui/Button'
import { LoaderCircle } from 'lucide-react'
import useCreateProjectMutation from '~/graphql/hooks/useCreateProjectMutation'

interface NewProjectModalParams {
  orgId?: string
  isCreating: boolean
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>
  trigger: JSX.Element
}

const projectNameSchema = z.object({
  projectName: z.string().trim().min(1, 'Project name is required')
})

type ProjectNameForm = z.infer<typeof projectNameSchema>

const NewProject = ({
  orgId,
  isCreating,
  setIsCreating,
  trigger
}: NewProjectModalParams) => {
  const [isDisabled, setIsDisabled] = useState(true)

  const { createProject } = useCreateProjectMutation({
    refetchQueries: ['ProjectsQuery']
  })

  const {
    register,
    watch,
    getValues,
    handleSubmit,
    reset,
    formState: { errors, isValid }
  } = useForm<ProjectNameForm>({
    resolver: zodResolver(projectNameSchema),
    mode: 'onChange'
  })

  const projectNameInput = register('projectName')
  const projectName = watch('projectName')

  useEffect(() => {
    setIsDisabled(isCreating || !getValues('projectName') || !isValid)
  }, [getValues, isCreating, isValid, projectName])

  const onSubmit = (data: ProjectNameForm) => {
    if (!orgId) return
    setIsCreating(true)

    createProject({
      name: data.projectName,
      orgId: parseInt(orgId, 10)
    })
      .then(() => {
        toast('Created project ' + data.projectName)
      })
      .catch((e) => {
        console.error('Error creating project:', e)
        toast('Error creating project: ' + e)
      })
      .finally(() => {
        setIsCreating(false)
        reset()
      })
  }

  return (
    <Dialog
      title="Create Project"
      className="p-0"
      overlay={
        <Card className="border-none shadow-none">
          <CardHeader>
            <CardTitle>Create Project</CardTitle>
            <CardDescription>Create a new project to get started.</CardDescription>
          </CardHeader>
          <CardContent>
            <form
              id="create-project-form"
              onSubmit={(event) => void handleSubmit(onSubmit)(event)}
            >
              <Input
                type="text"
                placeholder="Project name"
                {...register('projectName')}
                onChange={(_, event) => void projectNameInput.onChange(event)}
                error={errors.projectName?.message}
              />
            </form>
          </CardContent>
          <CardFooter>
            <div className="w-full gap-2 flex justify-end">
              <DialogClose>
                <Button variant="ghost">Cancel</Button>
              </DialogClose>
              <DialogClose>
                <Button type="submit" form="create-project-form" disabled={isDisabled}>
                  {isCreating && <LoaderCircle className="mr-2 h-5 w-5 animate-spin" />}
                  Create
                </Button>
              </DialogClose>
            </div>
          </CardFooter>
        </Card>
      }
    >
      {trigger}
    </Dialog>
  )
}

export default NewProject
