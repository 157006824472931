import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import cn from '~/utilities/classNames'

export const badgeVariants = cva(
  'inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground',
        neutral: 'border-transparent bg-neutral-300 text-content',
        success: 'border-transparent bg-green-100 text-green-900',
        warning: 'border-transparent bg-yellow-100 text-yellow-900',
        error: 'border-transparent bg-red-100 text-red-800'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  label?: string
}

export function Badge({ label, className, variant, ...props }: BadgeProps) {
  if (label)
    return (
      <div className={cn(badgeVariants({ variant }), className)} {...props}>
        {label}
      </div>
    )
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />
}

export default Badge
