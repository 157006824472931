import { useMutation, gql } from '@apollo/client'
import { MutationHookParams } from '../sharedTypes/types'

export interface UpdateProjectResponse {
  updateProject: {
    id: number
    name: string
  } | null
}

export interface UpdateProjectInput {
  id: number
  name: string
}

const UPDATE_PROJECT_MUTATION = gql`
  mutation ($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      name
    }
  }
`

export const useUpdateProjectMutation = (options?: MutationHookParams) => {
  const [mutation, { data, loading, error }] = useMutation<UpdateProjectResponse>(
    UPDATE_PROJECT_MUTATION,
    options
  )

  const updateProject = (input: UpdateProjectInput) =>
    mutation({
      variables: { input }
    })

  return {
    updateProject,
    data,
    loading,
    error
  }
}

export default useUpdateProjectMutation
