import useAdmin from '~/graphql/hooks/useAdmin'
import { JobRowData, QualityStatus } from './types'
import { toast } from 'sonner'
import {
  Select,
  SelectTrigger,
  SelectGroup,
  SelectItem,
  SelectContent,
  SelectValue
} from '~/components/ui/Select'

interface QualityStatusSelectParams {
  job: JobRowData
}

const QualityStatusSelect = ({ job }: QualityStatusSelectParams) => {
  const { updateQualityStatus } = useAdmin()
  const status: number = job.internalQCReview?.qualityStatus ?? QualityStatus[0]

  const handleQualityStatusChange = (value: string) => {
    updateQualityStatus({
      jobId: job.id,
      qualityStatus: Number(value)
    })
      .then(() => {
        toast(
          `Updated ${job.id}. Setting quality status to ${QualityStatus[Number(value)]}`
        )
      })
      .catch((e) => {
        console.error('Error setting quality status :', e)
        toast('Error setting quality status: ' + e)
      })
      .finally(() => {
        const event = new CustomEvent('RerunSearch', {})
        window.dispatchEvent(event)
      })
  }

  return (
    <Select
      onValueChange={(value) => handleQualityStatusChange(value)}
      defaultValue={String(status)}
    >
      <SelectTrigger className="p-3 w-36">
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {Object.keys(QualityStatus)
            .filter((key) => Number(key) > 0)
            .map((key: string) => {
              return (
                <SelectItem key={key} value={key}>
                  {QualityStatus[Number(key)]}
                </SelectItem>
              )
            })}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

export default QualityStatusSelect
