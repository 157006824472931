import { z } from 'zod'

/**
 * Password complexity Regex:
 * Contain at least one digit
 * at least one special character
 * at least one uppercase letter
 */
const PASSWORD_COMPLEXITY_REGEX = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])/

export const registerSchema = z.object({
  email: z
    .string({
      required_error: 'Email is required'
    })
    .email({ message: 'You must enter a valid email' }),
  password: z
    .string({
      required_error: 'Password is requried'
    })
    .min(8, { message: 'The password must be at least 8 characters long' })
    .regex(PASSWORD_COMPLEXITY_REGEX, {
      message: 'The Password must contain at least 1 number and 1 special character'
    }),
  fullName: z
    .string({
      required_error: 'Full name is required'
    })
    .min(1, { message: 'Full name is required' }),
  emailList: z.boolean().default(false)
})

export const emailRegisterSchema = z.object({
  email: z
    .string({
      required_error: 'Email is required'
    })
    .email({ message: 'You must enter a valid email' })
})

export type FormValues = z.infer<typeof registerSchema>
export type EmailFormValues = z.infer<typeof emailRegisterSchema>
