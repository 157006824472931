import { Navigate, useSearchParams } from 'react-router-dom'
import Loading from '~/components/ui/Loading'
import { useAcceptInviteQuery } from '~/graphql/hooks/useAcceptInviteQuery'
import { useAuth } from '~/hooks/useAuth'

export default function AcceptInvite() {
  const [searchParams] = useSearchParams()
  const inviteToken = searchParams.get('token') ?? ''

  const { logout } = useAuth()

  const { acceptInvite } = useAcceptInviteQuery(inviteToken)

  /**
   * Goals:
   * Handle the following actions: accept, login, register
   *
   * login:
   * should clear out existin logged in user and then redirect to the login page
   *
   * register:
   * should clear out existin logged in user and then redirect to the register page
   *
   * accept:
   * should see the user
   *
   */

  if (acceptInvite?.action === 'accept') {
    return <Navigate to={'/org'} />
  }

  if (acceptInvite?.action === 'login') {
    void logout()
    window.localStorage.setItem('invite_token', inviteToken)
    return <Navigate to="/login" />
  }

  if (acceptInvite?.action === 'register') {
    void logout()
    window.localStorage.setItem('invite_token', inviteToken)
    return <Navigate to="/register" />
  }

  return <Loading />
}
