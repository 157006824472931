import { PropsWithChildren, useRef, FormEvent } from 'react'
import { toast } from 'sonner'
import { Member } from '~/graphql/hooks/useOrgMembersQuery'
import { useUpdateMemberMutation } from '~/graphql/hooks/useUpdateMemberMutation'
import { useDeleteMemberMutation } from '~/graphql/hooks/useDeleteMemberMutation'

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '../ui/Dialog'
import Label from '../ui/Label'
import Input from '../ui/Input'
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue
} from '../ui/Select'
import Button from '../ui/Button'
import { LoaderCircleIcon } from 'lucide-react'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from '../ui/AlertDialog'

type EditMemberDialogProps = PropsWithChildren<{
  member: Member
}>

export function EditMemberDialog({
  member,
  children
}: EditMemberDialogProps): JSX.Element {
  const roleSelectRef = useRef<HTMLButtonElement | null>(null)
  const closeRef = useRef<HTMLButtonElement | null>(null)

  const { updateMember, loading: updateMemberLoading } = useUpdateMemberMutation({
    refetchQueries: ['OrgMembersQuery']
  })

  const { deleteMember, loading: deleteMemberLoading } = useDeleteMemberMutation({
    refetchQueries: ['OrgMembersQuery']
  })

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = new FormData(event.target as HTMLFormElement)

    if (member.role === form.get('role')) {
      closeRef.current?.click()
      return
    }

    const updateMemberPayload = {
      id: member.memberId,
      role: form.get('role') as string
    }

    const response = await updateMember(updateMemberPayload)

    if (response) {
      toast.success(`Role updated for ${member.name}`)
      // For some reason we need the timeout
      // so we can trigger the click from the button ref
      setTimeout(() => closeRef.current?.click())
    }
  }

  const onConfirmDeletion = async () => {
    const response = await deleteMember(member.memberId)
    if (response) {
      toast.success(`Member ${member.name} removed.`)
      // For some reason we need the timeout
      // so we can trigger the click from the button ref
      setTimeout(() => closeRef.current?.click())
    }
  }

  const isLoading = updateMemberLoading || deleteMemberLoading

  return (
    <Dialog
      onOpenChange={(open) => {
        if (open)
          // Timeout required so the focus can be given
          setTimeout(() => roleSelectRef.current?.focus())
      }}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-[394px]">
        <DialogHeader>
          <DialogTitle>Edit Member</DialogTitle>
        </DialogHeader>
        <DialogDescription className="hidden">Edit member modal</DialogDescription>
        <div className="grid gap-4">
          <div className="grid gap-1">
            <Label>Display Name</Label>
            <Input
              className="pointer-events-none cursor-not-allowed border-0 p-0 h-auto"
              value={member.name}
              readOnly
            />
          </div>
          <div className="grid gap-1">
            <Label>Email Address</Label>
            <Input
              className="pointer-events-none cursor-not-allowed border-0 p-0 h-auto"
              value={member.email}
              readOnly
            />
          </div>
          <div className="grid gap-1">
            <form id="edit-member-form" onSubmit={(event) => void onSubmit(event)}>
              <Label>Role</Label>
              <Select defaultValue={member.role} name="role">
                <SelectTrigger ref={roleSelectRef}>
                  <SelectValue placeholder="Select a role" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="administrator">Administrator</SelectItem>
                  <SelectItem value="member">Member</SelectItem>
                </SelectContent>
              </Select>
            </form>
          </div>
          <hr />
          <DialogFooter className="sm:justify-between">
            <ConfirmDialog onConfirm={onConfirmDeletion}>
              <Button
                type="button"
                variant="link"
                className="p-0 text-destructive hover:no-underline"
                disabled={isLoading}
              >
                {deleteMemberLoading && (
                  <LoaderCircleIcon className="mr-2 h-5 w-5 animate-spin" />
                )}
                Remove User
              </Button>
            </ConfirmDialog>
            <div className="flex gap-4">
              <DialogClose asChild>
                <Button ref={closeRef} type="button" variant="ghost" disabled={isLoading}>
                  Cancel
                </Button>
              </DialogClose>
              <Button type="submit" form="edit-member-form" disabled={isLoading}>
                {updateMemberLoading && (
                  <LoaderCircleIcon className="mr-2 h-5 w-5 animate-spin" />
                )}
                Save Changes
              </Button>
            </div>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default EditMemberDialog

type ConfirmDialogProps = PropsWithChildren<{
  onConfirm: () => Promise<void>
}>

export function ConfirmDialog({ children, onConfirm }: ConfirmDialogProps) {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure you want to remove?</AlertDialogTitle>
          <AlertDialogDescription>
            This action can not be undone. However, if needed, you can invite the person
            again by sending an invite to their email.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={() => void onConfirm()}>
            Yes, Remove
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
