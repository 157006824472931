import { useCallback } from 'react'
import { gql, useQuery } from '@apollo/client'
import { sortDateDescendant } from '~/utilities/datetimeFormats'

export interface Project {
  id: number
  orgId: number
  orgName: string
  name: string
  thumbnail: string | null
  createdAt: string
  jobs: {
    id: number
  }[]
}

export interface ProjectsQueryResponse {
  projects: Project[]
}

const PROJECTS_QUERY = gql`
  query ProjectsQuery {
    projects {
      id
      orgId
      orgName
      name
      thumbnail
      createdAt
      jobs {
        id
      }
    }
  }
`

const useProjectsQuery = (orgId: number) => {
  const { data, loading, error, refetch } = useQuery<ProjectsQueryResponse>(
    PROJECTS_QUERY,
    {
      skip: orgId < 0
    }
  )

  const refetchProjects = useCallback(async () => {
    if (!orgId) return

    try {
      await refetch()
    } catch (error) {
      console.error(error)
    }
  }, [refetch, orgId])

  const projects =
    orgId > 0
      ? data?.projects
          .filter((project) => project.orgId === orgId)
          .toSorted((a, b) => sortDateDescendant(a.createdAt, b.createdAt))
      : []

  return {
    projects,
    loading,
    error,
    refetchProjects
  }
}

export default useProjectsQuery
