import { useOrgMembersQuery } from '~/graphql/hooks/useOrgMembersQuery'
import { sortBy } from 'lodash'

import {
  Table,
  TableHeader,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '~/components/ui/Table'
import Button from '~/components/ui/Button'
import Skeleton from '../ui/Skeleton'
import { PencilIcon } from 'lucide-react'
import { EditMemberDialog } from '~/components/Organization/EditMemberDialog'
import { InviteMemberDialog } from '~/components/Organization/InviteMemberDialog'

interface MemberListProps {
  orgId: number
}

export function MemberList({ orgId }: MemberListProps): JSX.Element {
  const { members, loading: membersLoading } = useOrgMembersQuery(orgId)

  const orgMembers = sortBy(
    members.filter((member) => member.memberId !== null),
    ['role', 'name']
  )
  return (
    <>
      <h4 className="text-xl font-semibold">Members</h4>

      {membersLoading || members.length > 0 ? (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Role</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {membersLoading && <TableBodySkeleton />}
            {!membersLoading &&
              orgMembers.map((member) => (
                <TableRow key={member.memberId}>
                  <TableCell className="capitalize">{member.name}</TableCell>
                  <TableCell>{member.email}</TableCell>
                  <TableCell>
                    <div className="flex justify-between">
                      <span>{member.role}</span>{' '}
                      <EditMemberDialog member={member}>
                        <button className="w-6 h-6 rounded-full hover:bg-divider inline-flex justify-center items-center">
                          <PencilIcon size={16} />
                        </button>
                      </EditMemberDialog>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      ) : (
        <p className="text-sm">There are no additional members</p>
      )}

      <div>
        <InviteMemberDialog>
          <Button>Invite User</Button>
        </InviteMemberDialog>
      </div>
    </>
  )
}

function TableBodySkeleton() {
  const rowsMock = [...new Array<null>(6).fill(null)]
  const cellsMock = [...new Array<null>(3).fill(null)]

  return rowsMock.map((_, rIndex) => (
    <TableRow key={rIndex}>
      {cellsMock.map((_, cIndex) => (
        <TableCell key={cIndex}>
          <Skeleton className="w-full h-5" />
        </TableCell>
      ))}
    </TableRow>
  ))
}
