import { PropsWithChildren, useRef } from 'react'
import { useParams } from 'react-router'
import { toast } from 'sonner'
import * as z from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useInviteUserMutation } from '~/graphql/hooks/useInviteUserMutation'

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '../ui/Dialog'
import Label from '../ui/Label'
import Input from '../ui/Input'
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue
} from '../ui/Select'
import Button from '../ui/Button'
import { LoaderCircleIcon } from 'lucide-react'

const inviteUserSchema = z.object({
  email: z
    .string()
    .trim()
    .email({ message: 'You must enter a valid email.' })
    .min(1, { message: 'User email is required.' }),
  role: z.enum(['administrator', 'member'])
})

type InviteUserForm = z.infer<typeof inviteUserSchema>

export function InviteMemberDialog({ children }: PropsWithChildren): JSX.Element {
  const { orgId: orgIdParam } = useParams<{ orgId: string }>()
  const closeRef = useRef<HTMLButtonElement | null>(null)

  const { inviteUser, loading: inviteUserLoading } = useInviteUserMutation()

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<InviteUserForm>({
    defaultValues: {
      email: '',
      role: 'member'
    },
    resolver: zodResolver(inviteUserSchema)
  })

  const onSubmit = async (form: InviteUserForm) => {
    const orgId = parseInt(orgIdParam!)
    const emails = form.email
    const role = form.role

    const response = await inviteUser(orgId, emails, role)

    if (response) {
      toast.success(`Invite for ${emails} sent!`)
      setTimeout(() => closeRef.current?.click())
    }
  }

  const onDialogOpenChange = (open: boolean) => {
    if (!open) reset()
  }

  const emailInput = register('email')
  return (
    <Dialog onOpenChange={onDialogOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-[394px]">
        <DialogHeader>
          <DialogTitle>Invite User</DialogTitle>
        </DialogHeader>
        <DialogDescription className="hidden">Invite User Dialog</DialogDescription>
        <form onSubmit={(event) => void handleSubmit(onSubmit)(event)}>
          <div className="grid gap-4">
            <div className="grid gap-1">
              <Label>Email Address</Label>
              <Input
                {...emailInput}
                onChange={(_, event) => void emailInput.onChange(event)}
                error={errors.email?.message}
              />
            </div>
            <div className="grid gap-1">
              <Label>Role</Label>
              <Select
                defaultValue="member"
                onValueChange={(role: InviteUserForm['role']) => setValue('role', role)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a role" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="administrator">Administrator</SelectItem>
                  <SelectItem value="member">Member</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <hr />
            <DialogFooter>
              <DialogClose asChild>
                <Button
                  ref={closeRef}
                  type="button"
                  variant="ghost"
                  disabled={inviteUserLoading}
                >
                  Cancel
                </Button>
              </DialogClose>
              <Button type="submit" disabled={inviteUserLoading}>
                {inviteUserLoading && (
                  <LoaderCircleIcon className="mr-2 h-5 w-5 animate-spin" />
                )}
                Send Invite
              </Button>
            </DialogFooter>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default InviteMemberDialog
