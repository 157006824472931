import { useMutation, gql } from '@apollo/client'
import { MutationHookParams } from '../sharedTypes/types'
import { User } from './useUser'

export interface UpdateUserResponse {
  updateUser: {
    user: User
    token: string
  }
}

export interface UpdateUserInput {
  name: string
  email: string
  emailList: boolean
}

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        firstName
        lastName
        email
        emailList
      }
      token
    }
  }
`

export const useUpdateUserMutation = (options?: MutationHookParams) => {
  const [mutation, { data, loading, error }] = useMutation<UpdateUserResponse>(
    UPDATE_USER_MUTATION,
    options
  )

  const updateUser = async (input: UpdateUserInput) => {
    const response = await mutation({
      variables: { input }
    })

    return response?.data?.updateUser
  }

  return {
    updateUser,
    data,
    loading,
    error
  }
}
