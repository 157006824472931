import { gql, useMutation } from '@apollo/client'
import { MutationHookParams } from 'gql/sharedTypes/types'

const CREATE_JOB_MUTATION = gql`
  mutation CreateJob($input: CreateJobInput!) {
    createJob(input: $input) {
      id
      name
      jobType
      matchType
      status
      message
      comparisonId
      diffUri
      annotationsUri
      originalDesign {
        id
        s3Key
        originalFilename
        pageCount
      }
      revisedDesign {
        id
        s3Key
        originalFilename
        pageCount
      }
      downloadUrls
    }
  }
`

export enum JobType {
  drawing = 1,
  text = 2
}

export enum MatchType {
  auto = 1,
  manual = 2,
  skip = 3
}

export interface CreateJobInput {
  projectId: number
  jobType: JobType
  originalDesignId: number
  revisedDesignId: number
  matchType: MatchType
  name: string
}

export interface Job {
  id: number
}

export interface CreateJobResponse {
  createJob: Job
}

export const useCreateJobMutation = (options?: MutationHookParams) => {
  const [mutation, { data, loading, error }] = useMutation<CreateJobResponse>(
    CREATE_JOB_MUTATION,
    {
      ...options,
      refetchQueries: ['ProjectQuery']
    }
  )

  const createJob = async (jobPayload: CreateJobInput) => {
    const response = await mutation({
      variables: {
        input: jobPayload
      }
    })

    return response.data?.createJob
  }

  return {
    createJob,
    data,
    loading,
    error
  }
}
