import { useEffect, PropsWithChildren, useRef } from 'react'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useUpdateReviewMutation } from '~/graphql/hooks/useUpdateReviewMutation'

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '~/components/ui/Card'
import Dialog, { DialogClose } from '~/components/Dialog'
import Input from '~/components/ui/Input'
import Button from '~/components/ui/Button'
import { LoaderCircle } from 'lucide-react'

type ReviewSettingsProps = PropsWithChildren<{
  reviewId: number
  reviewName: string
}>

const reviewSettingsSchema = z.object({
  reviewName: z.string().trim().min(1, 'Review name is required')
})

type ReviewSettingsForm = z.infer<typeof reviewSettingsSchema>

const ReviewSettingsDialog = ({
  reviewId,
  reviewName,
  children
}: ReviewSettingsProps) => {
  const closeRef = useRef<HTMLButtonElement>(null)

  const { updateReview, loading: updateReviewLoading } = useUpdateReviewMutation()

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<ReviewSettingsForm>({
    resolver: zodResolver(reviewSettingsSchema),
    mode: 'onChange'
  })

  const reviewNameInput = register('reviewName')

  const onSubmit = async (form: ReviewSettingsForm) => {
    const updateReviewPayload = {
      jobId: reviewId,
      name: form.reviewName
    }

    try {
      const updatedReview = await updateReview(updateReviewPayload)
      if (updatedReview?.id) {
        closeRef.current?.click()
      }
    } catch (error: unknown) {
      console.error(error)
    }
  }

  useEffect(() => {
    setValue('reviewName', reviewName)
    return () => reset()
  }, [reset, reviewName, setValue])

  return (
    <Dialog
      title="Rename Review"
      className="p-0"
      overlay={
        <Card className="border-none shadow-none">
          <CardHeader>
            <CardTitle>Rename Review</CardTitle>
            <CardDescription>Enter a new name for your review.</CardDescription>
          </CardHeader>
          <CardContent>
            <form
              id="review-settings-form"
              onSubmit={(event) => void handleSubmit(onSubmit)(event)}
            >
              <Input
                type="text"
                placeholder="Review Name"
                {...reviewNameInput}
                onChange={(_, event) => void reviewNameInput.onChange(event)}
                error={errors.reviewName?.message}
              />
            </form>
          </CardContent>
          <CardFooter>
            <div className="w-full gap-2 flex justify-end">
              <DialogClose>
                <Button variant="ghost" ref={closeRef}>
                  Cancel
                </Button>
              </DialogClose>

              <Button
                type="submit"
                form="review-settings-form"
                disabled={updateReviewLoading}
              >
                {updateReviewLoading && (
                  <LoaderCircle className="mr-2 h-5 w-5 animate-spin" />
                )}
                Save
              </Button>
            </div>
          </CardFooter>
        </Card>
      }
    >
      {children}
    </Dialog>
  )
}

export default ReviewSettingsDialog
