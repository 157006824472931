import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '../ui/Dialog'
import Button from '../ui/Button'
import Label from '../ui/Label'
import Input from '../ui/Input'
import { useUser } from '~/graphql/hooks/useUser'
import { Switch } from '../ui/Switch'
import { LoaderCircleIcon } from 'lucide-react'
import { useUpdateUserMutation } from '~/graphql/hooks/useUpdateUserMutation'
import { useAuth } from '~/hooks/useAuth'

interface AccountSettingsDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const accountSettingsSchema = z.object({
  name: z.string().trim().min(3),
  email: z.string().trim().email(),
  emailList: z.boolean()
})

type AccountSettings = z.infer<typeof accountSettingsSchema>

export default function AccountSettingsDialog({
  open,
  setOpen
}: AccountSettingsDialogProps) {
  const { user, refetchUserData } = useUser()
  const { setToken } = useAuth()
  const { updateUser, loading: updateUserLoading } = useUpdateUserMutation()

  const isGoogleLogin = user?.authenticatedFrom === 'gauth' ? true : false

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<AccountSettings>({
    defaultValues: {
      name: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`,
      email: user?.email ?? '',
      emailList: user?.emailList ?? false
    },
    resolver: zodResolver(accountSettingsSchema)
  })

  const onSubmit = async (formData: AccountSettings) => {
    const updateUserPayload = {
      ...formData
    }
    const updateUserResponse = await updateUser(updateUserPayload)
    if (updateUserResponse?.user?.id) {
      setOpen(false)
    }

    if (updateUserResponse?.token && updateUserResponse.token.length > 0) {
      setToken(updateUserResponse.token)
    }

    await refetchUserData()
  }

  const handleModalOpenChange = (open: boolean) => {
    if (!open) reset()
    setOpen(false)
  }

  return (
    <Dialog onOpenChange={handleModalOpenChange} open={open}>
      <DialogContent className="min-w-[394px]">
        <DialogHeader className="mb-3">
          <DialogTitle>My Account</DialogTitle>
          <DialogDescription className="sr-only">My Account</DialogDescription>
        </DialogHeader>
        <form
          id="create-job-form"
          onSubmit={(event) => void handleSubmit(onSubmit)(event)}
        >
          <div className="grid gap-6">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <div className="self-end grid gap-2">
                  <Label htmlFor="name" className="text-sm">
                    Display Name
                  </Label>
                  <Input
                    id="name"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.name?.message}
                  />
                </div>
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <div className="self-end grid gap-2">
                  <Label htmlFor="email" className="text-sm">
                    Email Address
                  </Label>
                  <Input
                    id="email"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.email?.message}
                    disabled={isGoogleLogin}
                  />
                </div>
              )}
            />
            <Controller
              name="emailList"
              control={control}
              render={({ field }) => (
                <div className="self-end flex gap-2">
                  <Switch
                    id="emailList"
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                  <Label htmlFor="emailList" className="text-sm ">
                    Subscribe to MBUE Email Updates
                  </Label>
                </div>
              )}
            />
          </div>
        </form>
        <DialogFooter>
          <div className="flex gap-4">
            <Button
              variant="secondary"
              onClick={() => {
                reset()
                setOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button type="submit" form="create-job-form" disabled={updateUserLoading}>
              {updateUserLoading && (
                <LoaderCircleIcon className="mr-2 h-5 w-5 animate-spin" />
              )}
              Save Changes
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
