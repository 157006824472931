import useAdmin from '~/graphql/hooks/useAdmin'
import { JobRowData } from './types'
import { toast } from 'sonner'
import Textarea from '~/components/ui/Textarea'

interface CommentTextareaParams {
  job: JobRowData
}

const CommentTextarea = ({ job }: CommentTextareaParams) => {
  const { updateComment } = useAdmin()
  const handleCommentTextareaBlurCapture = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    updateComment({
      jobId: job.id,
      comment: e.target.value
    })
      .then(() => {
        toast(`Updated comment for jobId: ${job.id}.`)
      })
      .catch((e) => {
        console.error('Error setting comment :', e)
        toast('Error setting comment: ' + e)
      })
      .finally(() => {
        const event = new CustomEvent('RerunSearch', {})
        window.dispatchEvent(event)
      })
  }

  const status: number = job.internalQCReview?.reviewStatus ?? 0
  if (status > 0) {
    return (
      <Textarea
        onBlurCapture={(e) => void handleCommentTextareaBlurCapture(e)}
        readOnly={false}
        defaultValue={job.internalQCReview?.comment ?? ''}
      />
    )
  }
}

export default CommentTextarea
