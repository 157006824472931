import { useCallback } from 'react'
import { FetchResult } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from '@uidotdev/usehooks'
import { googleLogout } from '@react-oauth/google'
import { useDevCycleClient } from '@devcycle/react-client-sdk'
import { useLoginMutation, LoginResponse, Credentials } from 'gql/hooks/useLoginMutation'
import {
  useEmailLoginMutation,
  LoginEmailResponse,
  type EmailCredentials
} from '~/graphql/hooks/useEmailLoginMutation'
import { useUser, User } from '~/graphql/hooks/useUser'
import Mixpanel from '~/utilities/mixpanel'
import { toast } from 'sonner'

interface Auth {
  login: (credentials: Credentials) => Promise<FetchResult<LoginResponse>>
  emailLogin: (
    emailCredentials: EmailCredentials
  ) => Promise<FetchResult<LoginEmailResponse>>
  logout: () => Promise<void>
  token: string | null
  setToken: (token: string | null) => void
  getUser: () => Promise<{ me: User | null } | null>
  loading: boolean
}

export function useAuth(): Auth {
  const devcycleClient = useDevCycleClient()
  const {
    refetchUserData,
    clearCache: clearUserCache,
    loading: userIsLoading
  } = useUser()
  const [token, setToken] = useLocalStorage<string | null>('access_token', null)
  const navigate = useNavigate()

  const {
    login,
    loading: loginIsLoading,
    client
  } = useLoginMutation({
    onCompleted(data: LoginResponse) {
      if (data.login?.data?.token) {
        setToken(data.login.data.token)
        Mixpanel.identify({
          id: data.login.data.user.id.toString(),
          data: {
            email: data.login.data.user.email,
            framework: 'react'
          }
        })

        Mixpanel.track({
          event: 'FE:login',
          data: {
            framework: 'react',
            source: 'email-password'
          }
        })
        void devcycleClient.identifyUser({
          user_id: data.login.data.user.id.toString()
        })
        void refetchUserData()
        navigate('/org')
      }
      if (data.login?.error) {
        toast.error(data.login.error)
      }
    }
  })

  const { emailLogin, loading: emailLoginIsLoading } = useEmailLoginMutation({
    onCompleted(data: LoginEmailResponse) {
      if (data.emailLogin?.success && data.emailLogin.data?.token) {
        setToken(data.emailLogin.data.token)
        Mixpanel.identify({
          id: data.emailLogin.data.user.id.toString(),
          data: {
            email: data.emailLogin.data.user.email,
            framework: 'react'
          }
        })
        void devcycleClient.identifyUser({
          user_id: data.emailLogin.data.user.id.toString()
        })
        window.location.reload()
      }

      if (data.emailLogin?.success) {
        navigate('/?login=success')
      }

      if (data.emailLogin?.error) {
        toast.error(data.emailLogin?.error)
      }
    }
  })

  const getUser = useCallback(async (): Promise<{ me: User | null } | null> => {
    try {
      const validatedUser = await refetchUserData()
      return validatedUser?.data ?? null
    } catch (error) {
      console.error(error)
      return null
    }
  }, [refetchUserData])

  const loading = loginIsLoading || userIsLoading || emailLoginIsLoading

  const logout = async () => {
    await client.resetStore()
    clearUserCache()
    setToken(null)
    googleLogout()
    Mixpanel.track({ event: 'FE:logout', data: { framework: 'react' } })
    Mixpanel.reset()
    navigate('/')
  }

  return {
    login,
    emailLogin,
    logout,
    token,
    setToken,
    getUser,
    loading
  }
}
