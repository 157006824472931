import { useMutation, gql } from '@apollo/client'
import { MutationHookParams } from 'gql/sharedTypes/types'

export interface LoginEmailResponse {
  emailLogin: {
    success: boolean
    error: string | null
    data: {
      token: string
      user: {
        id: number
        email: string
      }
    } | null
  } | null
}

export interface EmailCredentials {
  email: string
  credentials: string | null
}

const EMAIL_LOGIN_MUTATION = gql`
  mutation EmailLoginMutation($input: EmailLoginInput!) {
    emailLogin(input: $input) {
      success
      error
      data {
        token
        user {
          id
          email
        }
      }
    }
  }
`

export const useEmailLoginMutation = (options: MutationHookParams) => {
  const [mutation, { data, loading, error }] = useMutation<LoginEmailResponse>(
    EMAIL_LOGIN_MUTATION,
    options
  )

  const emailLogin = (credentials: EmailCredentials) =>
    mutation({
      variables: {
        input: credentials
      }
    })

  return {
    emailLogin,
    data,
    loading,
    error
  }
}
