import { useState, useEffect, FC, PropsWithChildren, useRef } from 'react'
import { useLocation } from 'react-router'
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar'
import { Navigate } from 'react-router'
import { useAuth } from 'hooks/useAuth'
import { User } from 'gql/hooks/useUser'

interface ProtectedRouteProps extends PropsWithChildren {
  admin?: boolean
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, admin = false }) => {
  const topLoaderRef = useRef<LoadingBarRef>(null)
  const location = useLocation()
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { getUser } = useAuth()

  useEffect(() => {
    topLoaderRef.current?.continuousStart()
    const runEffect = async () => {
      try {
        const validatedUser = await getUser()
        if (validatedUser?.me) {
          setUser(validatedUser.me)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
        topLoaderRef.current?.complete()
      }
    }
    void runEffect()
  }, [getUser, location])

  if (!isLoading && !user?.email) return <Navigate to="/login" replace />

  if (!isLoading && admin && !user?.isAdmin) return <Navigate to="/org" replace />

  return (
    <>
      <LoadingBar
        color="#e44386"
        ref={topLoaderRef}
        transitionTime={100}
        loaderSpeed={200}
        waitingTime={400}
      />
      {children}
    </>
  )
}

export default ProtectedRoute
