import React, { ChangeEvent } from 'react'

import classNames from '~/utilities/classNames'
import { InputProps, NumberInputProps } from './types'

const Input = React.forwardRef<HTMLInputElement, InputProps | NumberInputProps>(
  ({ wrapperClassName, className, type, onChange, error, ...props }, ref) => {
    const onChangeFn = (e: ChangeEvent<HTMLInputElement>) => {
      if (!onChange) return

      if (type === 'number') {
        ;(onChange as NumberInputProps['onChange'])?.(e.target.valueAsNumber, e)
      } else {
        ;(onChange as InputProps['onChange'])?.(e.target.value, e)
      }
    }

    return (
      <div className={classNames('grid gap-1', wrapperClassName)}>
        <input
          type={type}
          className={classNames(
            'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            className
          )}
          ref={ref}
          {...props}
          onChange={onChangeFn}
        />
        {error && <p className="text-red-500 text-xs">{error}</p>}
      </div>
    )
  }
)
Input.displayName = 'Input'

export { Input as default }
