import { useState, FC, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
import { Job, useProjectQuery } from '~/graphql/hooks/useProjectQuery'
import moment from 'moment'
import { useVariableValue } from '@devcycle/react-client-sdk'
import { Plus as PlusIcon, EllipsisIcon } from 'lucide-react'

import { MenuSimple as MenuSimpleIcon } from '~/components/ui/icons/MenuSimple'
import { DrawerLayout, DrawerContent, DrawerMainContent } from '~/components/DrawerLayout'
import Button from '~/components/ui/Button'
import ReviewsList from '~/components/Reviews/ReviewsList'
// import DoctypeTabs from '~/components/DoctypeTabs'
import DownloadsMenuButton from '~/components/Reviews/DownloadsMenuButton'
import NewReviewDialog from '~/components/Reviews/NewReviewDialog'
import Badge, { BadgeProps } from '~/components/ui/Badge'
import { reviewTime } from '~/utilities/datetimeFormats'
import ReviewSettingsDialog from '~/components/Reviews/ReviewSettingsDialog'
import Skeleton from '~/components/ui/Skeleton'
import TipsForBetterResultsDialog from '~/components/Reviews/TipsForBetterResultsDialog'
import SmartReporting from '~/components/Reviews/SmartReporting'

const POLLING_INTERVAL_5_MINUTES = 5 * 60 * 1000

const Reviews: FC = () => {
  const isSmartReportEnabled = useVariableValue('smart-reporting-enabled', false)
  const navigate = useNavigate()
  const { orgId, projectId, reviewId } = useParams<{
    orgId: string
    projectId: string
    reviewId: string
  }>()
  const [createReviewOpen, setCreateReviewOpen] = useState<boolean>(false)
  const [asideOpen, setAsideOpen] = useState<boolean>(true)
  const [selectedReview, setSelectedReview] = useState<Job | null>()

  const skipReviewTips =
    (window.localStorage.getItem('skip_review_tips') ?? 'false') === 'true'

  const {
    project,
    loading: isProjectLoading,
    startPolling,
    stopPolling
  } = useProjectQuery({
    projectId: parseInt(projectId!)
  })

  useEffect(() => {
    if (reviewId && project?.jobs) {
      const review = project.jobs.find((job) => job.id.toString() === reviewId)

      setSelectedReview(review)

      const { label } = getReviewStatus(review?.statusDetail ?? '')

      if ('In Progress' === label) {
        startPolling(POLLING_INTERVAL_5_MINUTES)
      } else {
        stopPolling()
      }
    } else if (!reviewId && project?.jobs.length) {
      const review = project.jobs[0]
      navigate(`/org/${orgId}/project/${projectId}/reviews/${review.id}`)
    } else {
      setSelectedReview(null)
    }
  }, [navigate, orgId, project?.jobs, projectId, reviewId, startPolling, stopPolling])

  return (
    <>
      <DrawerLayout open={asideOpen} setOpen={setAsideOpen} hideCloseButton={!reviewId}>
        <DrawerContent className="h-full flex flex-col w-[368px]">
          <div className="h-[72px] flex justify-between items-center px-6 border-b border-divider">
            <h4 className="text-xl font-semibold">Reviews</h4>
            <div className="flex gap-4">
              {skipReviewTips ? (
                <Button className="flex gap-2" onClick={() => setCreateReviewOpen(true)}>
                  <PlusIcon size={16} />
                  <span>New</span>
                </Button>
              ) : (
                <TipsForBetterResultsDialog onClose={() => setCreateReviewOpen(true)}>
                  <Button className="flex gap-2">
                    <PlusIcon size={16} />
                    <span>New</span>
                  </Button>
                </TipsForBetterResultsDialog>
              )}
            </div>
          </div>
          <div className="overflow-y-auto flex-1">
            <ReviewsList isLoading={isProjectLoading} jobs={project?.jobs ?? []} />
          </div>
        </DrawerContent>

        <DrawerMainContent className="w-full h-[calc(100vh_-_56px)]">
          {selectedReview && (
            <div className="pr-6 pl-4 flex flex-col h-full pb-4">
              <div className="h-[72px] flex justify-between items-center border-b border-divider">
                <div className="flex gap-2 items-center">
                  <button
                    onClick={() => setAsideOpen((prevState) => !prevState)}
                    className={`border border-divider rounded-full p-[3px] ${asideOpen && 'hidden'}`}
                  >
                    <MenuSimpleIcon />
                  </button>
                  <h2 className="text-xl text-content font-semibold">
                    {selectedReview?.name ??
                      selectedReview?.revisedDesign.originalFilename}
                  </h2>
                  <ReviewSettingsDialog
                    reviewName={selectedReview.name ?? ''}
                    reviewId={selectedReview.id}
                  >
                    <button
                      type="button"
                      className="bg-fundation-emphasis rounded-full p-1"
                    >
                      <EllipsisIcon size={16} />
                    </button>
                  </ReviewSettingsDialog>
                </div>
                <div className="flex gap-2 items-center">
                  <Link
                    to={`/org/${orgId}/project/${projectId}/reviews/${reviewId}/view`}
                    onClick={(event) => {
                      if (
                        !selectedReview.downloadUrls.diff &&
                        selectedReview.jobType !== 'text'
                      )
                        event.preventDefault()
                    }}
                  >
                    <Button disabled={!selectedReview.downloadUrls.diff}>
                      Open in Viewer
                    </Button>
                  </Link>
                  <DownloadsMenuButton
                    originalUrl={selectedReview.downloadUrls.original}
                    revisedUrl={selectedReview.downloadUrls.revised}
                    diffUrl={selectedReview.downloadUrls.diff}
                  />
                </div>
              </div>
              <div className="flex justify-between items-center py-2">
                <div className="grid">
                  <div>
                    <Badge
                      {...(getReviewStatus(
                        selectedReview.statusDetail ?? '',
                        selectedReview.jobType
                      ) as BadgeProps)}
                    />
                  </div>
                  <p className="text-sm text-disabled">
                    {selectedReview.completedAt ? 'Completed ' : 'Created '}
                    {moment(
                      selectedReview.completedAt ??
                        selectedReview.startedAt ??
                        selectedReview.createdAt
                    ).fromNow()}
                  </p>
                  <p className="text-sm text-disabled">
                    Review Time:{' '}
                    {reviewTime(
                      selectedReview.startedAt ?? selectedReview.createdAt,
                      selectedReview.completedAt ?? ''
                    )}
                  </p>
                </div>
                <div>{/* <DoctypeTabs value={selectedReview.jobType} /> */}</div>
              </div>
              {isSmartReportEnabled ? (
                <SmartReporting />
              ) : (
                <div
                  className="flex-grow bg-muted grid place-content-center bg-cover bg-center border border-divider rounded-md"
                  style={{
                    backgroundImage: `url(${selectedReview.downloadUrls.preview})`
                  }}
                ></div>
              )}
            </div>
          )}

          {isProjectLoading && <LoadingState />}

          {!isProjectLoading && (project?.jobs ?? []).length === 0 && (
            <EmptyState open={createReviewOpen} setOpen={setCreateReviewOpen} />
          )}
        </DrawerMainContent>
      </DrawerLayout>
      <NewReviewDialog open={createReviewOpen} setOpen={setCreateReviewOpen} />
    </>
  )
}

export default Reviews

function LoadingState() {
  return (
    <div className="px-4 flex flex-col h-full pb-4">
      <div className="border-b border-b-divider h-[72px] py-1">
        <Skeleton className="w-full h-full flex items-center justify-between px-2">
          <Skeleton className="w-1/3 h-9" />
          <Skeleton className="w-1/4 h-9" />
        </Skeleton>
      </div>
      <div className="flex-grow flex flex-col">
        <div className="py-1">
          <Skeleton className="w-full h-[72px] flex flex-col gap-[2px] justify-center px-4">
            <Skeleton className="w-[64px] h-4" />
            <Skeleton className="w-1/4 h-4" />
            <Skeleton className="w-1/4 h-4" />
          </Skeleton>
        </div>
        <div className="py-2 flex-grow">
          <Skeleton className="w-full h-full grid place-content-center">
            <Skeleton className="h-10 w-32" />
          </Skeleton>
        </div>
      </div>
    </div>
  )
}

function EmptyState({ setOpen }: { open?: boolean; setOpen: (open: boolean) => void }) {
  const skipReviewTips =
    (window.localStorage.getItem('skip_review_tips') ?? 'false') === 'true'
  return (
    <div className="w-full h-[calc(100vh_-_56px)] grid place-content-center">
      <div className="grid gap-2">
        <p>Select a Review to view it, or</p>
        {skipReviewTips ? (
          <Button className="flex gap-2" onClick={() => setOpen(true)}>
            <PlusIcon size={16} />
            <span>New</span>
          </Button>
        ) : (
          <TipsForBetterResultsDialog onClose={() => setOpen(true)}>
            <Button className="flex gap-1">
              <PlusIcon size={16} />
              <span>Start a new Review</span>
            </Button>
          </TipsForBetterResultsDialog>
        )}
      </div>
    </div>
  )
}

function getReviewStatus(
  status: string,
  type?: string
): { label: string; variant: string } {
  if (!status) return { label: 'unknown', variant: 'neutral' }

  if (type === 'text') return { label: 'Completed', variant: 'success' }

  const progress = ['Setup', 'Review Starting', 'Smart Matching', 'User Matching']
  const error = ['Review Error', 'Setup Error', 'Smart Matching Error']

  if (progress.includes(status)) return { label: 'In Progress', variant: 'warning' }
  if (error.includes(status)) return { label: 'Error', variant: 'error' }
  return { label: 'Completed', variant: 'success' }
}
