import { useMutation, gql } from '@apollo/client'
import { MutationHookParams } from '../sharedTypes/types'

export interface DeleteMemberResponse {
  deleteMember: boolean
}

const DELETE_MEMBER_MUTATION = gql`
  mutation DeleteMemberMutation($id: Int!) {
    deleteMember(id: $id)
  }
`

export const useDeleteMemberMutation = (options?: MutationHookParams) => {
  const [mutation, { data, loading, error }] = useMutation<DeleteMemberResponse>(
    DELETE_MEMBER_MUTATION,
    options
  )

  const deleteMember = async (id: number) => {
    const response = await mutation({
      variables: { id }
    })

    return response.data?.deleteMember ?? false
  }

  return {
    deleteMember,
    data,
    loading,
    error
  }
}
