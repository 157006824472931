import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { MutationHookParams } from 'gql/sharedTypes/types'

const CREATE_DESIGN_MUTATION = gql`
  mutation CreateDesignMutation($input: CreateDesignInput!) {
    createDesign(input: $input) {
      id
      s3Key
      originalFilename
      fileSizeBytes
      pageCount
      uploadUrl
    }
  }
`

export interface CreateDesignInput {
  projectId: number
  s3Key: string
  originalFilename: string
  pageCount: number
  fileSizeBytes: number
}

export interface CreateDesign extends Omit<CreateDesignInput, 'projectId'> {
  id: number
  uploadUrl: string
}

export interface CreateDesignResponse {
  createDesign: CreateDesign
}

export const useCreateDesignMutation = (options?: MutationHookParams) => {
  const [isLoading, setIsloading] = useState<boolean>(false)

  const [mutation, { data, loading, error }] = useMutation<CreateDesignResponse>(
    CREATE_DESIGN_MUTATION,
    options
  )

  const uploadDocument = async (uploadUrl: string, file: File) => {
    try {
      const response = await fetch(uploadUrl, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/pdf' },
        body: file
      })

      if ([200, 201].includes(response.status)) {
        return true
      }
    } catch (error: unknown) {
      console.error(error)
    }
    return false
  }

  const createDesign = async (designPayload: CreateDesignInput, file: File) => {
    try {
      setIsloading(true)
      const response = await mutation({
        variables: {
          input: designPayload
        }
      })

      if (response.data?.createDesign.uploadUrl) {
        const uploadedFile = await uploadDocument(
          response.data.createDesign.uploadUrl,
          file
        )

        if (uploadedFile) {
          return response.data.createDesign
        }
      }
    } catch (error: unknown) {
      console.error(error)
    } finally {
      setIsloading(false)
    }
  }

  return {
    createDesign,
    uploadDocument,
    data,
    loading: loading || isLoading,
    error
  }
}
