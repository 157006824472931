import { useMutation, gql } from '@apollo/client'
import { MutationHookParams } from '../sharedTypes/types'

export interface UpdateOrgResponse {
  updateOrg: boolean
}

export interface UpdateOrgInput {
  id: number
  name: string
  type: string
}

const UPDATE_ORG_MUTATION = gql`
  mutation UpdateOrg($input: UpdateOrgInput!) {
    updateOrg(input: $input)
  }
`

export const useUpdateOrgMutation = (options?: MutationHookParams) => {
  const [mutation, { data, loading, error }] = useMutation<UpdateOrgResponse>(
    UPDATE_ORG_MUTATION,
    options
  )

  const updateOrg = async (input: UpdateOrgInput) => {
    const response = await mutation({
      variables: { input }
    })

    return response.data?.updateOrg ?? false
  }

  return {
    updateOrg,
    data,
    loading,
    error
  }
}
