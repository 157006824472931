import { gql, useQuery } from '@apollo/client'
import { keyBy } from 'lodash'
import { formatReviewName } from '~/utilities/stringFormats'

export interface NavigationDataResponse {
  me: {
    orgs: {
      id: number
      name: string
    }[]
  }
  projects: {
    id: number
    name: string
    orgId: number
    jobs: {
      id: number
      name: string
      revisedDesign: {
        id: number
        originalFilename: string
      }
    }[]
  }[]
}

const NAVIGATION_DATA_QUERY = gql`
  query NavigationDataQuery {
    projects {
      id
      name
      orgId
      jobs {
        id
        name
        revisedDesign {
          id
          originalFilename
        }
      }
    }
    me {
      orgs {
        id
        name
      }
    }
  }
`

export const useNavigationDataQuery = () => {
  const { data, loading, error, refetch } =
    useQuery<NavigationDataResponse>(NAVIGATION_DATA_QUERY)

  return {
    navigationData: mapResponseToResult(data),
    loading,
    error,
    refetch
  }
}

const mapResponseToResult = (data: NavigationDataResponse | undefined) => {
  if (!data) return null
  if (!data.projects || !data.me) return null

  const clonedData = structuredClone(data)

  const projects = clonedData.projects.map((project) => {
    return {
      ...project,
      jobs: keyBy(
        project.jobs.map((job) => ({
          id: job.id,
          name: job.name ? job.name : formatReviewName(job.revisedDesign.originalFilename)
        })),
        (job) => job.id
      )
    }
  })

  const orgs = clonedData.me.orgs.map((org) => {
    return {
      ...org,
      projects: keyBy(
        projects.filter((project) => project.orgId === org.id),
        (project) => project.id
      )
    }
  })

  return {
    orgs: keyBy(orgs, (org) => org.id)
  }
}
