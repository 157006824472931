import { useMutation, gql } from '@apollo/client'
import { MutationHookParams } from '../sharedTypes/types'

export interface UpdateMemberResponse {
  editMember: boolean
}

export interface UpdateMemberInput {
  id: number
  role: string
}

const UPDATE_MEMBER_MUTATION = gql`
  mutation EditMemberMutation($input: OrgMemberInput!) {
    editMember(input: $input)
  }
`

export const useUpdateMemberMutation = (options?: MutationHookParams) => {
  const [mutation, { data, loading, error }] = useMutation<UpdateMemberResponse>(
    UPDATE_MEMBER_MUTATION,
    options
  )

  const updateMember = async (input: UpdateMemberInput) => {
    const response = await mutation({
      variables: { input }
    })

    return response.data?.editMember ?? false
  }

  return {
    updateMember,
    data,
    loading,
    error
  }
}
