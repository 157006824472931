import { useMutation, gql } from '@apollo/client'
import { MutationHookParams } from '../sharedTypes/types'

export interface UpdateReviewResponse {
  updateJob: {
    id: number
    name: string
  } | null
}

export interface UpdateReviewInput {
  jobId: number
  name: string
}

const UPDATE_REVIEW_MUTATION = gql`
  mutation UpdateReviewMutation($input: UpdateJobInput!) {
    updateJob(input: $input) {
      id
      name
    }
  }
`

export const useUpdateReviewMutation = (options?: MutationHookParams) => {
  const [mutation, { data, loading, error }] = useMutation<UpdateReviewResponse>(
    UPDATE_REVIEW_MUTATION,
    options
  )

  const updateReview = async (input: UpdateReviewInput) => {
    const response = await mutation({
      variables: { input }
    })

    if (response.data?.updateJob?.id) {
      return response.data.updateJob
    }

    return null
  }

  return {
    updateReview,
    data,
    loading,
    error
  }
}

export default useUpdateReviewMutation
