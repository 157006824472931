import { useEffect, useRef, useState } from 'react'
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer'
import { env } from '~/env.ts'

import { Job } from '~/graphql/hooks/useReviewQuery'
import { AnnotationPayload, loadAnnotations } from '~/utilities/annotations'

import {
  drawAnnotations,
  setDefaultViewerSettings,
  addAnnotationTooltips,
  loadTextCompare
} from '~/utilities/webviewer'

import { toast } from 'sonner'
import { useUser } from '~/graphql/hooks/useUser'

const WebviewerComponent = ({ review }: { review: Job }) => {
  const viewerDiv = useRef<HTMLDivElement>(null)
  const [webviewerInstance, setWebviewerInstance] = useState<WebViewerInstance | null>(
    null
  )
  const beenInitialised = useRef<boolean>(false)
  const [annotations, setAnnotations] = useState<AnnotationPayload | null>(null)
  const { user } = useUser()

  useEffect(() => {
    if (beenInitialised.current) return
    beenInitialised.current = true

    let webviewerKey: string = env.VITE_WEBVIEWER_DEMO_TOKEN ?? ''
    if (env.VITE_ENV === 'prod') {
      if (!user?.isAdmin) {
        webviewerKey = env.VITE_WEBVIEWER_TOKEN ?? ''
      }
    }

    const webviewerConfig = {
      path: '/webviewer',
      licenseKey: webviewerKey,
      fullAPI: true,
      initialDoc: review.jobType === 'drawing' ? review.diffUri : review.originalUri
    }

    if (review.jobType === 'drawing') {
      loadAnnotations(review.annotationJsonUri)
        .then((annots) => {
          if (annots) {
            setAnnotations(annots)
          }
        })
        .catch((e) => {
          console.error('Failed to load annotations', e)
          toast(`mbue annotations failed to load.`)
        })
    }

    WebViewer(webviewerConfig, viewerDiv.current!)
      .then((inst) => {
        setWebviewerInstance(inst)
      })
      .catch((e) => {
        console.error('There was an error initializing the webviewer.', e)
        toast(`There was an error displaying your review. (webviewer)`)
      })
  }, [review, user?.isAdmin])

  useEffect(() => {
    if (webviewerInstance && annotations) {
      webviewerInstance.Core.documentViewer.addEventListener('documentLoaded', () => {
        void drawAnnotations(webviewerInstance, annotations)
        addAnnotationTooltips(webviewerInstance)
      })
    }
  }, [annotations, webviewerInstance])

  useEffect(() => {
    if (webviewerInstance && review) {
      // Some basic config for the webviewer
      void setDefaultViewerSettings(webviewerInstance)

      if (review.jobType === 'text') {
        void loadTextCompare(webviewerInstance, review.originalUri, review.revisedUri)
      }
    }
  }, [review, webviewerInstance])

  return <div className="h-full w-full viewer" ref={viewerDiv} />
}

export default WebviewerComponent
