import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { CredentialResponse } from '@react-oauth/google'

import logo from 'assets/images/mbue_logo_magenta.png'
import { EmailLoginSuccess } from './EmailLoingSucces'
import { LoginForm } from './LoginForm'

export default function Login() {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const { login, emailLogin, getUser, loading } = useAuth()
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const isPasswordEnabled = params.get('password') === 'true'
  const isEmailLoginSuccess = email.trim() && params.get('login') === 'success'

  const onLoginFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    if (isPasswordEnabled) {
      const credentials = {
        username: email,
        password,
        inviteToken: window.localStorage.getItem('invite_token') ?? null
      }

      void login(credentials)
      return
    }

    const emailCredentials = {
      email,
      credentials: null
    }

    try {
      void emailLogin(emailCredentials)
    } catch (error) {
      console.error('There was an error during login. Please try again.')
    }
    return
  }

  const onGoogleLoginSuccess = (credentialResponse: CredentialResponse) => {
    // Avoids to loging again if the user clicks twice on the Google Button
    if (loading) return

    if (credentialResponse.credential) {
      const emailCredentials = {
        email: '',
        credentials: credentialResponse.credential
      }

      void emailLogin(emailCredentials)
    }
  }

  useEffect(() => {
    const runEffect = async () => {
      try {
        const validatedUser = await getUser()
        if (validatedUser?.me?.email) {
          navigate(`/org`)
        }
      } catch (error) {
        console.error(error)
      }
    }
    void runEffect()
  }, [getUser, navigate])

  return (
    <div className="w-full h-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="flex gap-6 flex-col items-center ">
            <img className="w-48" src={logo} alt="mbue" />
            <h1 className="text-3xl font-bold">
              {!isEmailLoginSuccess ? 'Login' : 'Check your email'}
            </h1>
          </div>

          {!isEmailLoginSuccess ? (
            <LoginForm
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              isPasswordEnabled={isPasswordEnabled}
              onSubmit={onLoginFormSubmit}
              onGoogleLoginSuccess={onGoogleLoginSuccess}
              loading={loading}
            />
          ) : (
            <EmailLoginSuccess
              email={email}
              onResendEmail={(event) => void onLoginFormSubmit(event)}
            />
          )}
        </div>
      </div>
      <div className="hidden bg-muted lg:block">
        <img
          src="https://images.squarespace-cdn.com/content/v1/61dca87d8e88f25fc40b0493/2d2a02eb-5b1f-43e7-b74d-ccca82e82897/AdobeStock_601259045.jpeg?format=1500w"
          alt="Image"
          width="1920"
          height="1080"
          className="h-screen w-full object-cover dark:brightness-[0.2] dark:grayscale drag-none -scale-x-100 object-[-335px] 2xl:object-[-450px]"
        />
      </div>
    </div>
  )
}
