import { useMutation, gql } from '@apollo/client'
import { MutationHookParams } from 'gql/sharedTypes/types'
export interface LoginResponse {
  login: {
    success: boolean
    error?: string | null
    data: {
      user: {
        id: number
        email: string
      }
      token: string
    } | null
  } | null
}

export interface Credentials {
  username: string
  password: string
  inviteToken?: string | null
}

const LOGIN_MUTATION = gql`
  mutation LoginMutation($username: String!, $password: String!, $inviteToken: String) {
    login(username: $username, password: $password, inviteToken: $inviteToken) {
      success
      error
      data {
        user {
          id
          email
        }
        token
      }
    }
  }
`

export const useLoginMutation = (options?: MutationHookParams) => {
  const [mutation, { data, loading, error, client }] = useMutation<LoginResponse>(
    LOGIN_MUTATION,
    options
  )

  const login = (credentials: Credentials) =>
    mutation({
      variables: credentials
    })

  return {
    login,
    data,
    loading,
    error,
    client
  }
}
