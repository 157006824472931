import moment from 'moment'

export function reviewTime(createdAt: string | Date, completedAt: string | Date): string {
  const created = moment(createdAt)
  const completed = moment(completedAt)

  if (created.isValid() && completed.isValid()) {
    return moment.duration(moment(completedAt).diff(createdAt)).humanize()
  }

  return '-'
}

export function sortDateDescendant(a: Date | string, b: Date | string) {
  const dateA = new Date(a)
  const dateB = new Date(b)
  return dateB.getTime() - dateA.getTime()
}
