import React, { FC, PropsWithChildren } from 'react'
import { ChevronLeft as CloseIcon } from 'lucide-react'
import { env } from '~/env'

export const DrawerContent: FC<PropsWithChildren<{ className?: string }>> = ({
  className,
  children
}) => {
  return <div className={className}>{children}</div>
}

export const DrawerMainContent: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className
}) => {
  return <section className={className}>{children}</section>
}

export const DrawerLayout: FC<
  PropsWithChildren<{
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    hideCloseButton: boolean
  }>
> = ({ open, setOpen, hideCloseButton, children }) => {
  const childrenArray = React.Children.toArray(children)

  if (childrenArray.length !== 2) {
    console.error(
      'DrawerLayout: The component requires exactly two children: the DrawerContent and MainContent components.'
    )
    return null
  }

  const drawerContent = childrenArray[0]

  if (
    env.VITE_ENV !== 'development' &&
    !(React.isValidElement(drawerContent) && drawerContent.type === DrawerContent)
  ) {
    console.error(
      'DrawerLayout: The first child component must be an instance of the DrawerContent component.'
    )
    return null
  }
  const mainContent = childrenArray.slice(1)
  return (
    <div className="relative w-full h-full flex">
      <div className={hideCloseButton ? 'hidden' : ''}>
        <button
          onClick={() => setOpen((prevState) => !prevState)}
          className={`${!open ? 'invisible left-0' : 'left-[368px]'} transition-all duration-300 absolute top-6 z-10 -translate-x-1/2`}
        >
          <CloseIcon className="bg-fundation rounded-full border border-divider" />
        </button>
      </div>
      <aside
        className={`border-r border-divider transition-all duration-300 overflow-hidden ${open ? 'w-[368px]' : 'w-0'}`}
      >
        <div
          className={`overflow-y-auto h-full transition-opacity ${open ? 'opacity-100' : 'opacity-0'}`}
        >
          {drawerContent}
        </div>
      </aside>

      <div className={`overflow-x-auto ${open ? '!flex-1' : 'w-full'}`}>
        {mainContent}
      </div>
    </div>
  )
}
